import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table-6";

import gql from "graphql-tag";
import { useQuery, useMutation, useSubscription } from "@apollo/client";

import XLSX from 'xlsx';

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import People from "@material-ui/icons/People";
import PersonAdd from "@material-ui/icons/PersonAdd";
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import Close from "@material-ui/icons/Close";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import EditIcon from "@material-ui/icons/Edit";
import HistoryIcon from '@material-ui/icons/History';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import SendIcon from '@material-ui/icons/Send';
import CardActionArea from '@material-ui/core/CardActionArea';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import CircularProgress from '@material-ui/core/CircularProgress';
import Tooltip from '@material-ui/core/Tooltip';
import BuildIcon from '@material-ui/icons/Build';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import util from "util.js";

import StudentHistory from "components/StudentHistory/StudentHistory.js";
import OrganizationChooser from "components/StudentTable/OrganizationChooser.js";
import BulkImportDialog from "components/StudentTable/BulkImportDialog.js";
import StudentEdit from "components/StudentTable/StudentEdit.js";
import GetAppIcon from '@material-ui/icons/GetApp';
import Fab from '@material-ui/core/Fab';

import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";

import Queries from "GraphQL/InstructorAccess.js"
import SubscriptionHelpers from "GraphQL/SubscriptionHelpers.js"

import distiAuth from "disti-auth.js";

distiAuth.init({userType: "INSTRUCTOR"});

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  }
};
const DefaultColumnFilter = ( { filter, onChange })  =>
{
  return (
	<input
	  value={filter ? filter.value : ''}
	  onChange={e => {
		  //console.log("Got onChange: "+ JSON.stringify(e.target.value));
		onChange(e.target.value || []) // Set undefined to remove the filter entirely
	  }}
	  placeholder={`🔍`}//Search ${count} records...`}
	/>
  )
 
}
const previewPhase3 = window.location.search.includes("phase3=40");


const columns = [
                {
                  Header: "Name",
                  accessor: "fullName",
				  Filter: DefaultColumnFilter,
                },
                {
                  Header: "Email",
                  accessor: "email",
				  Filter: DefaultColumnFilter,
                },				
                {
                  Header: "Market",
                  accessor: "marketName",
				  Filter: DefaultColumnFilter,
                },              
                {
                  Header: "Dealer",
                  accessor: "dealer",
				  Filter: DefaultColumnFilter,
                },              
                {
                  Header: "",
                  accessor: "actions",
                  sortable: false,
                  filterable: false,
                  width: 250
                }

               ]
  const defaultSorted=[
				{
				  id: "fullName",
				  desc: false
				}
			  ]
const defaultFilterMethod = (filter, row, column) => {
    const id = filter.pivotId || filter.id
    if (row[id] !== undefined )
    {
        const haystack =(String(row[id])).toLowerCase();
        const needle = String(filter.value).toLowerCase();
        return haystack.includes(needle);
    }
    return true;
}

const reactTableStyle = {
				height: "calc( 100vh - 276px)" 
			  }
const useStyles = makeStyles(styles);
 

export default function App() {
	
  const {allowedActions, assignedMarkets} = React.useContext( distiAuth.AllowedActionsContext );
  
  let { loading: queryLoading, data: queryData } = SubscriptionHelpers.useSubscribedStudentsQuery();  //useQuery(Queries.STUDENTS.ALL); 
  const [ deleteStudent, {loading: deleteStudentLoading, error: deleteStudentError} ] = useMutation(Queries.STUDENTS.DELETE);
  
  const [ createStudent, {loading: createStudentLoading, error: createStudentError} ] = useMutation(Queries.STUDENTS.CREATE);
  
  const [ updateStudent, {loading: updateStudentLoading, error: updateStudentError} ] = useMutation(Queries.STUDENTS.UPDATE);
  
  const [ showHistoryOf, setShowHistoryOf ] = React.useState("");
  
  const [ editStudent, setEditStudent ] = React.useState(null);
  const [ showCreateStudent, setShowCreateStudent ] = React.useState(false);
  
  const [menuAnchorElement, setMenuAnchorElement] = React.useState(null);
  const [menuTargetData, setMenuTargetData] = React.useState(null);
  
  const errorSub = false
  
  const hiddenFileInput = React.useRef(null);
  
  let reactTable = React.useRef(null);
  const [pageSize, setPageSize] = React.useState(null);
  
  /*
  const {error: errorSub} = useSubscription(Queries.STUDENTS.ALL_SUBSCRIPTION, {
      //subscriptionFailedCallback: (e)=>console.log(e),
      shouldResubscribe: true,
	  onSubscriptionData: ({ client, subscriptionData })=>{

		console.log("Got student subscriptionData: "+JSON.stringify(subscriptionData));
		
		const cachedData = client.readQuery({
		  query: Queries.STUDENTS.ALL
		});
		
		// If one already exists, replace it.
		const filteredItems = cachedData.listStudents.items.filter((val)=>{ 
			return val.id != subscriptionData.data.onStudentsChange.id; 
		});
		
		if (!subscriptionData.data.onStudentsChange.deleted)
		{
			filteredItems.push(subscriptionData.data.onStudentsChange)
		}
			
		// If the data argument isn't a copy, this will fail to trigger an update
		client.writeQuery({
		  query: Queries.STUDENTS.ALL,
		  data: {
			  "listStudents": {"items": [...filteredItems],
							   "__typename":cachedData.listStudents.__typename} 
		  }
		})
	  }
	});
    */
  const { data: marketQueryData } = useQuery(Queries.MARKETS.ALL);       
  let marketDataById = {};
  if (marketQueryData && marketQueryData.listMarkets.items)
  {
      marketQueryData.listMarkets.items.forEach((item)=>
      {
         marketDataById[item.id] = item; 
      });
  }
      
  if (errorSub)
  {
   console.log("Got errorSub: "+JSON.stringify(errorSub));
  }
  if (deleteStudentError)
  {
	  console.log("Mutate error: "+JSON.stringify(deleteStudentError));
  }
  if (createStudentError)
  {
	  console.log("Problem creating student: "+createStudentError);
  }
  if (updateStudentError)
  {
	  console.log("Problem updating student: "+updateStudentError);
  }

  async function doDelete(pk)
  {
	  try
	  {
        await deleteStudent({variables: {"pk": pk}});
        console.log("Success with deleteStudent");
	  }
	  catch(e)
	  {
		  console.log("Problem in delete: "+e)
	  }
  }
  
  async function editName(item)
  {
	const fullName = window.prompt("Full Name", item.fullName);
	if (fullName)
	{
		await updateStudent({variables: {pk:item.id, fullName: fullName}});
	}	  
  }
  
  function doEditStudent( email )
  {
    setEditStudent( email );      
  }
  async function doCreate()
  {
    setShowCreateStudent(true);
  }
  async function doHistory(studentId)
  {
	  console.log("doHistory: "+studentId);
	  setShowHistoryOf(studentId);
  }
  
  async function doInvite(item)
  {
	  console.log("doInvite: "+item.id);
      if (window.confirm("Send email invitation to: "+item.fullName+"?"))
      {
            distiAuth.requestInviteEmail(item.id, "STUDENT",
                ()=>{
                    console.log("email invitation requested")},
                (e)=>{
                    console.log("problem doing email invite: "+e)
                })                
      }
  }
  
  
  let studentList = []//React.useMemo(()=>{
  let allStudentsRaw = []

      if (queryData && queryData.listStudents)
      {
          const assignedMarketsSet = new Set(assignedMarkets);
          
          allStudentsRaw = queryData.listStudents.items
          const allowedStudentList = [...allStudentsRaw].filter((item)=>{
              return allowedActions.noMarketRestrictions || assignedMarketsSet.has(item.market)          
          })
           studentList = allowedStudentList.map(item => {
                return {
                    id: item.id,
                    email: item.id,
                    market: item.market,
                    marketName: marketDataById[item.market] ? marketDataById[item.market].name : null,
                    dealer: item.dealer,
                    fullName: item.fullName,
                    actions: (
                      // we've added some custom button actions
                      <div className="actions-right">
                      <Button key={item.id}
                            round
                            size="sm"
                            color="primary" 
                            onClick={(event) => { handlePopupMenuForId(event, item.id) }
                            }
                          >
                            <BuildIcon/>
                            <ArrowDropDownIcon/>
                        </Button>
                      </div>
                    )				
                };
           }
           )	   
      }

  // const fixOldStyleMarkets =()=>
  // {
    // const marketDataByName = {}
    // if (marketQueryData && marketQueryData.listMarkets.items)
    // {
          // marketQueryData.listMarkets.items.forEach((item)=>
          // {
             // marketDataByName[item.name] = item; 
          // });
    // }

    // studentList.forEach(item=>{
        // const oldStyleMarket = item.market && !marketDataById.hasOwnProperty( item.market )
        
        // if (oldStyleMarket)
        // {
            // const hasStringMatchForOld = marketDataByName[item.market]
            // if (hasStringMatchForOld)
            // {
                // const newMarket = hasStringMatchForOld.id
                
                // const before = {pk:item.email, fullName: item.fullName, market: item.market, dealer: item.dealer }
                // console.log("Would change: "+JSON.stringify(before))
                // const after = {...before, market: newMarket}
                // console.log("          to: "+JSON.stringify(after))
                
                // try {
                    
                    // // Uncomment this at your own peril
                    // // updateStudent({variables: after});      
                
                // }
                // catch(e)
                // {
                    // console.log("Problem.  Stopping process: "+e)
                    // throw(e)
                // }
                
            // }
            // else
            // {
                // // Old style, but no exact match
                // console.log("!!!!!!!!!!!!!!!!!!!Could not find exact market match for: "+item.market)
            // }
        // }
    // })
  // }
  const dumpAllStudentData = ()=>
  {
      const details = studentList.map(item=>{
          return {
              email: item.email,
              fullName: item.fullName,
              market: item.marketName,
              dealer: item.dealer,
          }
      })
    const book =  XLSX.utils.book_new();
    const sheet = XLSX.utils.json_to_sheet(details);
    XLSX.utils.book_append_sheet(book, sheet, "Students");
    XLSX.writeFile(book, "student-data.xlsx");  
      
  }
      
//      return []
//  },[Boolean(queryData), queryData ? queryData.listStudents.length: 0 ])
  
  const noDataComponent = React.useCallback(()=>{
        return ( queryLoading ? 
                    <CircularProgress disableShrink style={{
                        position: "absolute",
                        top: "200px",
                        // Center it left/right:
                        left: "0px",
                        right: "0px",
                        marginLeft: "auto",
                        marginRight: "auto"
                    }} />: 
                    <div style={{
                        position: "absolute",
                        top: "200px",
                        // Center it left/right:
                        textAlign: "center",
                        left: "0px",
                        right: "0px",
                        marginLeft: "auto",
                        marginRight: "auto"
                    }}>No Students Visible</div> )
  },[queryLoading])

  const handlePopupMenuForId = React.useCallback((event, id) => { 
    setMenuAnchorElement(event.currentTarget);
    const student = studentList.find(item=>item.email == id)
    setMenuTargetData(student);
  },[studentList])
  
  const handleCloseMenu = ()=>
  {
    setMenuAnchorElement(null);
    setMenuTargetData(null);
  }
  
  const onFilteredChange = React.useCallback(() => {
		setPageSize(reactTable.current.getResolvedState().sortedData.length);
  },[])
  
  const hideHistoryDialog = () =>
  {
	  setShowHistoryOf("");
  }
  
  const PoupOptionsMenu = ()=>{
      return <Menu
                    id="action-menu"
                    anchorEl={menuAnchorElement}
                    open={Boolean(menuAnchorElement && menuTargetData)}
                    onClose={handleCloseMenu}
                  >
                   <MenuItem 
                     disabled={!allowedActions.createStudents}
                    onClick={()=>{
                         doEditStudent(menuTargetData.email)
                          handleCloseMenu();
                      }}>
                   <EditIcon style={{marginRight: "10px"}}/> Edit
                   </MenuItem>
                   <MenuItem 
                     disabled={!allowedActions.createStudents}
                        onClick={()=>{
                        doInvite(menuTargetData);
                        handleCloseMenu();
                      }}>
                      <SendIcon style={{marginRight: "10px"}}/> Send Invitation Email 
                  </MenuItem>
                  <MenuItem 
                     disabled={!allowedActions.viewStudentResults}
                          onClick={()=>{  
                            doHistory(menuTargetData.email)
                             handleCloseMenu();
                      }}>
                      <HistoryIcon style={{marginRight: "10px"}}/> View History
                   </MenuItem>                 
            </Menu>              
  }
  const classes = useStyles();
    return (
	<>
        <PoupOptionsMenu/>    
        {editStudent && !showCreateStudent ? (
            <StudentEdit 
                createNew={false} 
                initialValue={ studentList.filter((i)=>{ return i.id==editStudent})[0] } 
                onDelete={doDelete}
                onApply={(update)=>{
                    
                    updateStudent({variables: {pk:update.email, fullName: update.fullName, market: update.market, dealer: update.dealer }});
                    
                    }}
                onClose={()=>setEditStudent(null)}
            />) : ""}
        {showCreateStudent ? (
            <StudentEdit 
                createNew={true} 
                initialValue={ {} } 
                onValidateNewEmail={(candidateEmail)=>{
                  const lowerCandidate = (candidateEmail ||"").toLowerCase();
                  // Return valid:true if candidate is available to be used
                  // If a candidate should not be used, include the reason.

                  // if a student entry does not have a market attached, it is treated as available for creation.
                  // Note that no-market students are vestigual.  New students all require a market.
                  const foundWithMarket = queryData.listStudents.items.some( item => {return ((item.id.toLowerCase() == lowerCandidate) && item.market) } );

                  const foundInControlledMarket = studentList.some( item => {return item.id.toLowerCase() == lowerCandidate } );

                  return {valid: !foundWithMarket, reason: foundInControlledMarket?"Email already exists in the system":"Email already exists in another market in the system" };                    
                }}
                onApply={(update)=>{
                    
                    createStudent({variables: {pk:update.email, fullName: update.fullName, market: update.market, dealer: update.dealer }});
                    
                    }}
                onClose={()=>setShowCreateStudent(false)}
            />) : ""}
            
        
		<Dialog open={showHistoryOf !== ""} onClose={hideHistoryDialog} fullWidth={ true } maxWidth={false} aria-labelledby="form-dialog-title">
			<DialogTitle id="form-dialog-title">
				<CloseIcon style={{float:"right"}} fontSize="large" onClick={()=>setShowHistoryOf("")}/>
			</DialogTitle>
			<DialogContent>
				<StudentHistory
					username={showHistoryOf}
				/>
			</DialogContent>
		</Dialog>
        <BulkImportDialog 
            hiddenFileInput={hiddenFileInput}     
            onCreate={(update)=>{            
                // Both updates and creates occur... this handles both.
                //TODO: UNCOMMENT WHEN WE RELEASE IT: 
                //updateStudent({variables: {pk:update.email, fullName: update.fullName, market: update.market, dealer: update.dealer }});            
            }}            
            getCurrentEntryByEmail={(candidateEmail)=>{
                try
                {
					let rval = allStudentsRaw.find( (item)=>{ return item.id == candidateEmail } );
                    if (rval)
                    {
                        return { email: rval.id, fullName: rval.fullName, market: rval.market, dealer: rval.dealer };
                    }
                }catch(e)
                {
                }
                return null;
            }}
            />
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="primary" icon>
		    <CardIcon color="primary">
              <People />
            </CardIcon>
            <h1 className={classes.cardIconTitle}>Students
            <div style={{float:"right"}}>
            {distiAuth.showDownloadButtons()? <Button round color="primary" onClick={dumpAllStudentData}><GetAppIcon />Export Table</Button>: null}            
            {allowedActions.createStudents ?
            <Button round color="primary" onClick={ ()=>{doCreate();} }><PersonAdd />Add Student</Button>
            : ""}
                {previewPhase3 && allowedActions.bulkImportStudents ? (<>
                <Button round color="primary" onClick={ ()=>{
                    hiddenFileInput.current.click();                    
                    } }><GroupAddIcon />Bulk Add</Button>

                </>): ""}

                
            </div>
            </h1>
          </CardHeader>
          <CardBody>
            <ReactTable ref={(r)=>{reactTable.current = r}}
              data={studentList}
              filterable
 			  style={reactTableStyle}
			  defaultFilterMethod={defaultFilterMethod}
              columns={columns} 
              defaultSorted={defaultSorted}
			  onFilteredChange={onFilteredChange}			  
              pageSize={ pageSize != null ? pageSize : studentList.length }
			  
              showPaginationTop={false}
              showPaginationBottom={false}
              className="-striped -highlight"
              NoDataComponent={noDataComponent}
            />
            
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
	</>  );
}
