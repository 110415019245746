import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';

// react component for creating dynamic tables
import ReactTable from "react-table-6";

import gql from "graphql-tag";
import { useQuery, useMutation, useSubscription } from "@apollo/client";

import ReactToPrint from 'react-to-print';

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import PersonAdd from "@material-ui/icons/PersonAdd";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import Checkbox from "@material-ui/core/Checkbox";
import ErrorIcon from '@material-ui/icons/Error';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListIcon from '@material-ui/icons/List';
import CloseIcon from '@material-ui/icons/Close';
import PdfIcon from '@material-ui/icons/PictureAsPdf';

import AWS from "aws-sdk"
import distiAuth from "disti-auth.js"

import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  }
};

const useStyles = makeStyles(styles);

const columnFilter = ( { filter, onChange })  =>
{
  return (
	<input
	  value={filter ? filter.value : ''}
	  onChange={e => {
		  //console.log("Got onChange: "+ JSON.stringify(e.target.value));
		onChange(e.target.value || []) // Set undefined to remove the filter entirely
	  }}
	  placeholder={`🔍`}//Search ${count} records...`}
	/>
  )
 
}
/*                            logFile: summaryFile.replace("summary","log"),
                            s3ForRegion: s3ForRegion,
                            regionItem: regionItem
                            */

export default ({ username, logFileInfo, onApply }) => {
	
  const [showTrainingLogFile, setShowTrainingLogFile] = React.useState("");
  let reactTable = React.useRef(null);
  const [pageSize, setPageSize] = React.useState(null);
  
  const componentToPrintRef = React.useRef();
  const reactToPrintRef = React.useRef();

  const [_count, forceUpdate] = React.useReducer(x => x + 1, 0);
  const logFileList = React.useRef([]);
 
  const classes = useStyles();

  React.useEffect(() =>  {
	
	   let params = {
		Bucket: distiAuth.getReportsBucketByRegion(logFileInfo.regionItem.id),
		Key: logFileInfo.logFile
	   };
        logFileInfo.s3ForRegion.getObject(  params, function(err, data) {
		 if (err)
		 {
			 console.log("Error getting: "+logFileInfo.logFile);
		 }
		 else
		 {
			 gotLogFile(data);
		 }
	 });
	
  },[]);
	
	const gotLogFile = (logFileData)=>
	{
		console.log("Got summary: "+JSON.stringify(logFileData));		
		if (true)//summaryData.Body.type === "Buffer")
		{
			let buf = new Buffer(logFileData.Body, 'binary');
			let logData = JSON.parse(buf.toString());
			try {
				let temp = logData.AllNetworkEvents.reduce((result, item)=>{

					console.log("Got item: "+JSON.stringify(item));
					// TODO: Do some filtering of noise (maybe)
					result.push({
						dateTime: item.TimeStamp,
						networkVariable: item.NetworkVariableId,
						networkValue: item.Value
					});
					return result;
				},[]);
				logFileList.current = temp;
				forceUpdate();
			}
			catch(e)
			{
				console.log("Issue in goSummary: "+e);
			}
		}
	}
  
  var errorColumnStyle = {
		style: {
			borderRight: "1px solid black",
			bacgkroundColor: "red"
		}
  };

  // Due to limits in react-to-print we have to create a React.Component to hold our content that will be drawn and printed
  class ComponentToPrint extends React.Component {
  constructor(props) {
    super(props);
  }      
  render() {
      
    let isPrinting = this.props.isPrinting;
    return (
		
          <CardBody ref={componentToPrintRef}>

           {isPrinting ? (<h3>{username} on {logFileInfo.logFile.split("/").slice(-2)[0]}<br/></h3>): ""}
           
           <h3>Summary</h3>
            <ReactTable
              sortable={false}
              data={logFileInfo.summaryData}
              columns={[
                  {Header: "Region",
				  columns: [{
					  accessor: "region"
                    }]
                  },
                  {Header: "Country",
				  columns: [{
					  accessor: "countryCode"
                    }]
                  },                  
				{ Header: "Date/Time",
				  headerStyle: {"textAlign": "center", borderRightStyle: "inset"},
				  columns: [{
					  accessor: "dateTime"
				  }]
				},
                  {Header: "Content",
				  columns: [{
					  accessor: "content"
                    }]
                  },                  
                  {Header: "Lesson",
				  columns: [{
					  accessor: "lesson"
                    }]
                  },                  
                  {Header: "Mode",
				  columns: [{
					  accessor: "trainingMode"
                    }]
                  },
                {
                  Header: "Passed",
				  headerStyle: {"textAlign": "center", borderRightStyle: "inset"},
				  columns: [{
					  accessor: "passed"
				  }],
                },
                
              ]}
              pageSize={1}
              showPaginationTop={false}
              showPaginationBottom={false}
              className="-highlight"
            />
            <ReactTable
              sortable={false}
              data={logFileInfo.summaryData}
              columns={[
                {
                  Header: "Hints",
				  headerStyle: {"textAlign": "center", borderRightStyle: "inset"},
				  columns: [{
					  accessor: "hints"
				  }]
                },
                {
				  Header: "Errors",
				  headerStyle: {"textAlign": "center", borderRightStyle: "inset"},
				  columns: [
   					    {
						  Header: "Added",
						  accessor: "AddedActions",
						},
						{
						  Header: "Incorrect",
						  accessor: "IncorrectActions"
						},
						{
						  Header: "Missed",
						  accessor: "MissedActions"
						  //,getProps: () => errorColumnStyle
						},
						{
						  Header: "Order",
						  accessor: "OutOfOrderActions"
						  //,getProps: () => errorColumnStyle
						},
						{
							Header: "Total",
							accessor: "totalErrors"
						}
						]
                },
                {
                  Header: "Score",
				  headerStyle: {"textAlign": "center", borderRightStyle: "inset"},
				  columns: [{
					  accessor: "score"
				  }]
                }
              ]}
              pageSize={1}
              showPaginationTop={false}
              showPaginationBottom={false}
              className="-highlight"
            />
            {logFileInfo.summaryData[0].hintedActions.length ?
             logFileInfo.summaryData[0].hintedActions.map((i)=>{
                            return (<li style={{margin:"5px"}}>{i.DisplayText}</li>);
						  }): ""
            }
            <h3>Detailed Log</h3>
            <ReactTable ref={(r)=>{reactTable.current = r}}
             sortable={!isPrinting}
			 scrollY
             style={false/*!isPrinting*/ ?{
				height: "60vh" // This will force the table body to overflow and scroll, since there is not enough room
             } : {}}
			  data={logFileList.current}
              filterable={!isPrinting}
			  defaultFilterMethod={(filter, row, column) => {
				const id = filter.pivotId || filter.id
				if (row[id] !== undefined )
				{
					const haystack =(String(row[id])).toLowerCase();
					const needle = String(filter.value).toLowerCase();
					return haystack.includes(needle);
				}
				return true;
			  }}
              //getTdProps={(state, rowInfo, column, instance) => { 
              //  if (rowInfo === undefined )
              //  {
              //      return {};
              //  } 
              //  return { style: { whiteSpace: 'normal' } }; }}
              columns={[
				{ Header: "Date/Time",
				  accessor: "dateTime",
				  filterable: false
				},
                {
                  Header: "Network Variable",
                  accessor: "networkVariable",
				  Filter: columnFilter,
                  //style: { whiteSpace: "normal !important", textOverflow: "unset", overflow: "unset"},
                },
                {
                  Header: "Value",
                  accessor: "networkValue",
				  Filter: columnFilter,
				  headerStyle: {textAlign: 'left'},
                }
              ]}
			  defaultSorted={[
				{
				  id: "dateTime",
				  desc: true
				}
			  ]}			  
			  onFilteredChange={() => {
			    setPageSize(reactTable.current.getResolvedState().sortedData.length);
			  }}			  
              pageSize={ pageSize != null ? pageSize : logFileList.current.length }
              showPaginationTop={false}
              showPaginationBottom={false}
              className="-highlight"
            />
          </CardBody>

          
    );
  }
}
  
    return !logFileInfo?"":(
	<>
    <GridContainer >
      <GridItem xs={12}>
        <Card> 
        <CardHeader color="primary" icon>
		    <CardIcon color="primary">
              <ListIcon />
            </CardIcon>
            <h1 className={classes.cardIconTitle}>History Detail<Button 
                            round 
                            color="primary" 
                            onClick={()=>{
                                reactToPrintRef.current.handleClick()
                            }}
                          style={{float:"right"}}
                          >
						<PdfIcon/>	
                        </Button>            
                        </h1>
        </CardHeader>
        <h2><span><u>{username}</u> on <u>{logFileInfo.logFile.split("/").slice(-2)[0]}</u> </span></h2>
        <div style={{display:"none"}}>
          <ReactToPrint ref={reactToPrintRef}
            trigger={() => <button>Print this out!</button>}
            content={() => componentToPrintRef.current}
          />    
            <ComponentToPrint isPrinting={true} ref={componentToPrintRef}/>
        </div>
        <ComponentToPrint isPrinting={false}/>
        </Card>
      </GridItem>
    </GridContainer>
      
	</>
  );
}
