import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from "components/CustomButtons/Button.js";
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import LinearProgress from '@material-ui/core/LinearProgress';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import LiveTvIcon from '@material-ui/icons/LiveTv';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CardBody from "components/Card/CardBody.js";
import Checkbox from "@material-ui/core/Checkbox";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import UpdateIcon from '@material-ui/icons/Update';
import TextField from '@material-ui/core/TextField';
import EditIcon from '@material-ui/icons/Edit';
import PersonIcon from '@material-ui/icons/Person';
import EmailIcon from '@material-ui/icons/Email';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import InputAdornment from '@material-ui/core/InputAdornment';
import OrganizationChooser from "components/StudentTable/OrganizationChooser.js";
import DeleteIcon from '@material-ui/icons/Delete';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Autocomplete from '@material-ui/lab/Autocomplete';
import StudentSelector2 from "components/StudentSelector/StudentSelector2.js";
import People from "@material-ui/icons/People";
import DateRangeIcon from '@material-ui/icons/DateRange';
import PeopleIcon from '@material-ui/icons/People';
import ClassIcon from "@material-ui/icons/Class";
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import { useQuery, useMutation, useSubscription } from "@apollo/client";
import Queries from "GraphQL/InstructorAccess.js"
import { DateRangePicker } from "materialui-daterange-picker";
import moment from "moment-timezone";
import DateFnsUtils from '@date-io/moment'; // choose your lib
import distiAuth from "disti-auth.js";

import {
  addDays,
  startOfWeek,
  endOfWeek,
  addWeeks,
  startOfMonth,
  endOfMonth,
  addMonths,
} from 'date-fns';

import {
  DatePicker,
  TimePicker,
//  DateTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';


import util from "util.js";

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px" 
    },
  entryLine: {
    width:"100%",
    marginTop:"15px",
    marginBottom:"15px",
  },
  shortEntryLine: {
    marginTop:"15px",
    marginBottom:"15px",
  },
  narrowForm: {
    minWidth: 120,
  },  
});

const showMarketDealer = true; 

const supportSingleLesson = window.location.search.includes("phase3=88")

const supportChatSetting = true;

const supportVideoCapture = true; //window.location.search.includes("phase3=99")

const showScoreInfo = false

const getTimeZoneOptions = (showTimezoneOffset) => {
  const timeZones = moment.tz.names();
  const offsetTmz = [];

  for (const i in timeZones) {
    const tz = timeZones[i];
    const tzOffset = moment.tz(tz).format('Z');
    const value = ""+ parseInt(
      tzOffset
        .replace(':00', '.00')
        .replace(':15', '.25')
        .replace(':30', '.50')
        .replace(':45', '.75')
    ).toFixed(2);

    const timeZoneOption = {
      label: showTimezoneOffset ? `${tz} (GMT${tzOffset})` : tz,
      value
    };
    offsetTmz.push(timeZoneOption);
  }

  return offsetTmz;
};
const timeZoneOptions = getTimeZoneOptions(true);
const timeZoneGuess = moment.tz.guess();
const timeZoneGuessIndex = timeZoneOptions.findIndex((a) => {
      return a.label.startsWith( timeZoneGuess )
      });
console.log("timeZoneGuess: "+timeZoneGuess);


export default ({createNew, onApply, onClose, onDelete, initialValue, timesShownAsText, timesShowAsOffset}) => {
  const classes = useStyles();
  
  const reduceTimeFromIndex = (oldValue, newIndex) =>
  {
    let entry = timeZoneOptions[newIndex]
    let shortName = entry.label.match(/[^()]+/g)[1]
    return {index: newIndex, fullTimeName: entry.label, shortName: shortName,  offset: parseFloat(entry.value)}
  }
  const [currentTimeOffset, setCurrentTimeOffset] = React.useReducer(reduceTimeFromIndex,reduceTimeFromIndex(0, timeZoneGuessIndex));
  
  const { loading: contentLoading, data: contentData } = useQuery(Queries.CONTENT.ALL);
  
  const [singleLesson, setSingleLesson] = React.useState(initialValue.singleLesson || false);
  
  // Looks up the name of content by ID.
  let contentNameById = {};
  
  if (!contentLoading && contentData)
  {
      contentData.listContent.items.forEach((item)=>{
          contentNameById[item.id] = item.name;
      });
  }
  
    const onTimeZoneChange = (index) => {
          console.log("onTimeZoneChange: "+index);
          setCurrentTimeOffset(index)
    };

  const {allowedActions, assignedMarkets} = React.useContext( distiAuth.AllowedActionsContext );

  /*
  Region
  Description (changing to Name)
  Start Date
  End Date
  Daily Start Time
  Daily End Time
  Assigned Students
  Content
  */
  const { data: marketQueryData } = useQuery(Queries.MARKETS.ALL);     
  let allAllowedMarkets = [];
  let marketDataById = {};
  if (marketQueryData && marketQueryData.listMarkets.items)
  {
      const assignedMarketsSet = new Set(assignedMarkets);
      allAllowedMarkets = marketQueryData.listMarkets.items.filter((item)=>{
          return allowedActions.noMarketRestrictions || assignedMarketsSet.has(item.id)          
      })
      
      allAllowedMarkets.forEach((item)=>
      {
         marketDataById[item.id] = item;        
      });
  }
 
  const now = moment().utc()
  
    
  const [ id, setId ] = React.useState(initialValue.id || "");
  const [ region, setRegion ] = React.useState(initialValue.region || "");
  const [ name, setName ] = React.useState(initialValue.description || "");
  const [ startDate, setStartDate ] = React.useState(initialValue.startDate || moment().startOf('week').unix());
  const [ endDate, setEndDate ] = React.useState(initialValue.endDate || moment().endOf('week').unix());
  const [ dailyStartTime, setDailyStartTime ] = React.useState( "dailyStartTime" in initialValue ? initialValue.dailyStartTime : 0);
  const [ dailyEndTime, setDailyEndTime ] = React.useState( "dailyEndTime" in initialValue ? initialValue.dailyEndTime : 0);
  const [ students, setStudents ] = React.useState(initialValue.students || []);
  const [ content, setContent ] = React.useState(initialValue.content || []);
  const [ market, setMarket ] = React.useState(initialValue.market || "");
  const [ useWebcam, setUseWebcam ] = React.useState(Boolean(initialValue.useWebcam));
  const [ captureVideo, setCaptureVideo ] = React.useState(Boolean(initialValue.captureVideo));
  const [ hideChat, setHideChat ] = React.useState(Boolean(initialValue.hideChat));
  
  const [ showStudentSelector, setShowStudentSelector ] = React.useState(false);
  
  const [dateRangePickerOpen, setDateRangePickerOpen] = React.useState(false);
  const toggleDateRangePicker = () => setDateRangePickerOpen(!dateRangePickerOpen)
  
  // Of the form {
  //    1234: { someSetting: "someValue" },
  //    4567: { someOtherSetting: "someValue", anotherSetting: "anotherValue }}
  
  // Allows setting of a single state for content id
  // { id: 1234, setting: { someSettingId: "someSettingValue" } }
  const [contentSettingsByContentId, setSettingForContentId] = React.useReducer((state, action)=>{
    const rval = {...state}
    
    if (!rval[action.id])
    {
        rval[action.id] = {}
    }
    rval[action.id] = {...rval[action.id], ...action.setting}
    return rval
      
  }, JSON.parse(initialValue.contentSettings || "{}"))
  const getContentSettingForCurrentId = (settingId)=>
  {
      if (content.length >= 1)
      {
          const c = content[0]
          const settings = contentSettingsByContentId[c] || {}
          return settings[settingId]
      }
      return null
  }
  
  let availableSettings = []
  
  if (singleLesson && content[0] && contentData?.listContent?.items)
  {
      
      const contentEntry = contentData.listContent.items.find(item => parseInt(item.id) == content[0])
      
      if (contentEntry?.availableSettings)
      {
          try{
            availableSettings = JSON.parse(contentEntry.availableSettings)
            console.log("got available settings: ",availableSettings)
          }
          catch(e){ console.log("Issue with available lessons of:",content,e) }
      }
      console.log("Got contentEntry:",contentEntry)
  }
  
  
  const handleCancel = ()=>{
      onClose();
  };
  const handleApply = ()=>{
      
      //contentSettings is a string of JSON, so we have to convert it.
      //Also, we want to filter it to only settings with content
      let contentSettingsString = ""
      if (singleLesson)
      {
          // For now we only save contentsettings when we are in singleLesson mode
          let contentSettingsJSON = {}
          content.forEach(contentId=>{
              if (contentId in contentSettingsByContentId)
              {
                  contentSettingsJSON[contentId] = contentSettingsByContentId[contentId]
              }
          })
          contentSettingsString = JSON.stringify(contentSettingsJSON)
      }
      
      onApply({
          createNew,
          id,
          region,
          description: name,
          startDate,
          endDate,
          dailyStartTime,
          dailyEndTime,
          students,
          content,
          market,
          useWebcam,
          captureVideo,
          hideChat,
          contentSettings: contentSettingsString,
          singleLesson
      });
      onClose();
  };
  const handleDelete = ()=>{
      
    const isConfirmed = window.confirm("Are you sure you want to delete class: "+name+"?");

    if (isConfirmed && onDelete && id)
    {
        onDelete( id );
        onClose();
    }    
  };
  
  const contentItemsThatSupportSingleLessonMode = (contentData?.listContent?.items?.filter(item=>item.availableSettings?.includes("supportsDirectLink"))) || []
    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>

      <Dialog open={true} onClose={handleCancel} fullWidth={ true } maxWidth={"md"} aria-labelledby="form-dialog-title">
		<DialogTitle id="form-dialog-title"><CloseIcon style={{position: "absolute",
    right: "25px", zIndex: "1"}} fontSize="large" onClick={handleCancel}/>
</DialogTitle>
        <DialogContent>
	
        <GridContainer>
          <GridItem xs={12}>

            <Card className={classes.root}>
          <CardHeader color="primary" icon>
		    <CardIcon color="primary">
            
              <ClassIcon onClick={()=>{
                  window.alert("The Class ID is: "+id)                  
              }}/>
            </CardIcon>
            <h1 className={classes.cardIconTitle}>{createNew ? "Create Class" : "Edit Class"}                
            </h1>

          </CardHeader>            
            
              <CardContent>
                {showStudentSelector ? 
              		<StudentSelector2
                        initialList={students}
                        onApply = { (updatedList) => {  setStudents(updatedList)  }}
                        onClose = {() =>{setShowStudentSelector(false)}}
                        />
                :""}
                    <Typography color="textSecondary" variant="h5" component="h2" >
                    <TextField className={classes.entryLine} required 
                        value={name}                         
                        id="name" 
                        label="Class Name" 
                        InputProps={ {
                            endAdornment: <InputAdornment position="end"><ClassIcon/></InputAdornment>, 
                            error: !name
                        }}
                        onChange={(e)=>{ setName( e.target.value ) }} 
                    />
                    <br/>  
                    <TextField className={classes.entryLine} required 
                        value={(startDate ? moment.unix(startDate).format("DD MMM YYYY") : "?")+ " - " + (endDate ? moment.unix(endDate).format("DD MMM YYYY") : "?")} 
                        label="Date range of class"
                        onClick={()=>{setDateRangePickerOpen(true)}}
                        InputProps={{
                            readOnly: true,
                            endAdornment: <InputAdornment position="end"><DateRangeIcon/></InputAdornment>, 
                        }}
                        error={!Boolean(startDate && endDate)}
                      />
                    <br/>                                          
                    <Dialog open={dateRangePickerOpen} onClose={handleCancel} fullWidth={ true } maxWidth={"md"} aria-labelledby="form-dialog-title">
                        <DateRangePicker
                          open={true}
                          toggle={toggleDateRangePicker}
                          initialDateRange={{startDate:moment.unix(startDate), endDate: moment.unix(endDate)}}
                          onChange={(range) => {
                              setStartDate( moment(range.startDate).unix());
                              setEndDate( moment(range.endDate).unix());
                              setDateRangePickerOpen(false);
                          }}
                          definedRanges={[
                              {
                                  label: 'Today',
                                  startDate: now,
                                  endDate: now
                              },
                              {
                                label: 'This Week',
                                startDate: startOfWeek(now),
                                endDate: endOfWeek(now),
                              },                                  
                              {
                                label: 'Next Week',
                                startDate: startOfWeek(addWeeks(now, 1)),
                                endDate: endOfWeek(addWeeks(now, 1)),
                              },                                                            
                          ]}
                        />  
                    </Dialog>
                    <span style={{display: "inline-flex", width: "100%"}}>
                    <TimePicker
                      style={{width: "50%", whiteSpace: "nowrap"}}
                      ampm={false}
                      variant="inline"
                      margin="normal"
                      id="start-time-picker"
                      label={(<span>Daily Start Time *<span style={{marginLeft:"100px"}}>{"("+timesShownAsText+")"}</span></span>)}
                      value={ moment.unix(parseInt(dailyStartTime)% (60*60*24) + timesShowAsOffset*60*60 + moment().startOf('day').unix())}
                      onChange={(mom)=>{
                          if (!isNaN(mom))
                          {
                            setDailyStartTime( moment(mom).unix() - timesShowAsOffset*60*60  - moment().startOf('day').unix());                               
                          }
                          }}
                    />
                    <TimePicker required
                      style={{marginLeft:"15px", width: "50%"}} 
                      ampm={false}
                      variant="inline"
                      margin="normal"
                      id="end-time-picker"
                      label="Daily End Time"
                      value={ moment.unix(parseInt(dailyEndTime)% (60*60*24) + timesShowAsOffset*60*60 + moment().startOf('day').unix())}
                      onChange={(mom)=>{
                          if (!isNaN(mom))
                          {
                            setDailyEndTime( moment(mom).unix() - timesShowAsOffset*60*60  - moment().startOf('day').unix());                               
                          }
                          }}
                    />
                    </span>
                   
                    <br/>  
                    <br/>  
                    <span style={{display: "inline-flex", width: "100%"}}>
                    <OrganizationChooser wide className={classes.shortEntryLine} required
                        availableOrganizations={allAllowedMarkets} 
                        // Fix up the market from name to GUID
                        currentOrganization={market}
                        onOrganizationChange={(newValue)=>{
                            
                            setRegion(marketDataById[newValue].defaultRegion)
                            setMarket(newValue)
                        }}
                        error={!Boolean(market)}
                    />
                    <FormControl style={{marginLeft:"15px", width: "25%"}} className={ classes.narrowForm}> 
                        <InputLabel shrink id="region-label">
                          Region*
                        </InputLabel>
                        <Select className={classes.shortEntryLine}  
                            labelId="region-label"                    
                            error={!Boolean(region)}
                            value={region} onChange={(event)=>{setRegion(event.target.value)}} displayEmpty >
                          { 
                            distiAuth.getRegionDataItems().map(regionItem => {
                                return (<MenuItem key={regionItem.id} value={regionItem.id}>{regionItem.name}</MenuItem>)
                            } 
                           )
                          }
                        </Select>
                     </FormControl>
                     </span>
                    <br/>                
            
                    <span style={{display: "inline-flex", width: "100%"}}>
                    <TextField className={classes.entryLine}  
                        style={{width:"unset", flexGrow:"4"}}
                        value={"" + students ? students.length : 0 + " students"} 
                        label="Selected Students"
                        onClick={()=>{setShowStudentSelector(true);}}
                        InputProps={{
                            readOnly: true,
                            endAdornment: <InputAdornment position="end"><PeopleIcon/></InputAdornment>, 
                        }}
                      />
                      <span style={{flexGrow:"2", textAlign:"center"}}>
                      <FormControlLabel
                        control={<Checkbox color="primary" checked={useWebcam} name="useWebcamCheck"
                                    onChange={(event)=>{
                                        setUseWebcam(event.target.checked)
                                    }
                                    }/>}
                        label="Use Webcam"
                        labelPlacement="top"
                      />
                      </span>
                      {(supportVideoCapture)?
                      <span style={{flexGrow:"2", textAlign:"center"}}>
                      <FormControlLabel
                        control={<Checkbox color="primary" checked={captureVideo} name="captureVideo"
                                    onChange={(event)=>{
                                        setCaptureVideo(event.target.checked)
                                    }
                                    }/>}
                        label="Capture Video"
                        labelPlacement="top"
                      />
                      </span>:null}                      
                      {(supportChatSetting)?
                      <span style={{flexGrow:"2", textAlign:"center"}}>
                      <FormControlLabel
                        control={<Checkbox color="primary" checked={!hideChat} name="hideChat"
                                    onChange={(event)=>{
                                        setHideChat(!event.target.checked)
                                    }
                                    }/>}
                        label="Chat"
                        labelPlacement="top"
                      />
                      </span>:null}
                      {(supportSingleLesson || singleLesson)/*If single lesson is already on, go ahead and show it*/?
                      <span style={{flexGrow:"2", textAlign:"center"}}>
                      <FormControlLabel
                        control={<Checkbox color="primary" checked={singleLesson} name="singleLesson"
                                    onChange={(event)=>{
                                        setSingleLesson(event.target.checked)
                                    }
                                    }/>}
                        label="Single Lesson"
                        labelPlacement="top"
                      />
                      </span>
                      :null}
                    </span>
                    <br/>  
                    </Typography>
                    <br/>                       
                    {!singleLesson?<>
                    {/*contentData && contentData.listContent) ? contentData.listContent.items.map((item) => { */
                    contentData && contentData.listContent ?
                      <Autocomplete
                        multiple
                        disableClearable={true}
                        id="tags-standard"
                        options={contentData.listContent.items.map((item) => parseInt(item.id) ) }
                        getOptionLabel={(id) => {return contentNameById[id] ? contentNameById[id] : "---"}}
                        value={content}
                        onChange={(event, newValue)=>{
                            setContent(newValue);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="standard"
                            label="Content"
                          />
                        )}
                      />            
                    : "Loading..."}
                    </>
                    :<>
                    <div style={{display:"inline-flex", width:"100%"}}>
                    {contentData && contentData.listContent?<>
                    <FormControl style={{flexGrow:"2"}} className={ classes.narrowForm}> 
                        <InputLabel shrink id="region-label">
                          Content*
                        </InputLabel>
                        <Select className={classes.shortEntryLine}  
                            labelId="region-label"                    
                            error={!Boolean(content[0])}
                            value={content[0] || ""} 
                            onChange={(event)=>{
                                setContent([event.target.value])}} displayEmpty >
                            {                             
                            contentItemsThatSupportSingleLessonMode.map(item => {
                                return (<MenuItem key={item.id} value={item.id}>{contentNameById[item.id]}</MenuItem>)
                            } 
                           )
                          }
                        </Select>
                     </FormControl>
                     {availableSettings.map(setting=>
                         {
                             return (
                            <FormControl style={{marginLeft:"15px", flexGrow:"2"}} key={setting.id} className={ classes.narrowForm}> 
                                <InputLabel shrink id={setting.id+"-label"}>
                                    {setting.name + "*"}
                                </InputLabel>
                                <Select className={classes.shortEntryLine}  
                                    labelId={setting.id+"-label"}                
                                    error={!Boolean(getContentSettingForCurrentId(setting.id))}
                                    value={getContentSettingForCurrentId(setting.id)|| "" /*|| setting.options[0].id*/} 
                                    onChange={(event)=>{
                                        
                                        const newSetting = {}
                                        newSetting[setting.id] = event.target.value 
                                        setSettingForContentId({id: content[0], setting: newSetting})                                  
                                    }}
                                    displayEmpty >
                                    {                    
                                        setting.options.filter(item=>item.supportsDirectLink).map(item => {
                                            return (<MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>)
                                        })
                                    }
                                </Select>
                             </FormControl>
                         )})
                     }
                     {showScoreInfo?
                    <Typography color="textSecondary" variant="h5" component="div" >
                    <TextField style={{marginLeft:"15px", flexGrow:"2"}} className={ classes.narrowForm} required 
                        value={getContentSettingForCurrentId("passingScore")}                         
                        id="minScore" 
                        label="Passing Score" 
                        InputProps={ {
                            error: !Boolean(getContentSettingForCurrentId("passingScore")) || isNaN(getContentSettingForCurrentId("passingScore"))
                        }}
                        onChange={(event)=>{ 
                            const newSetting = {}
                            setSettingForContentId({id: content[0], setting: newSetting})
                            try{
                                newSetting.passingScore = event.target.value
                                setSettingForContentId({id: content[0], setting: newSetting})                                  
                            }
                            catch(e){ 
                                console.log("Invalid grade, ignoring. ",e) 
                                }
                        }} 
                    />
                    </Typography>
                     :null}
                    </>:"Loading..."}
                    </div>
                    </>}
              </CardContent>
              
              <CardActions>
              </CardActions>
            </Card>
            
          </GridItem>
        </GridContainer>
                  <Button  
                        color="primary" 
                        style={{float:"left", marginLeft:"100px", marginTop:"20px", marginBottom:"20px"}}
						onClick={ handleApply }
                        disabled={ !(
                            name && market && startDate && endDate && market && region
                        )}
                    >
                     Confirm
                  </Button>
                  <Button 
                        style={{float:"right", marginRight:"100px", marginTop:"20px", marginBottom:"20px"}}
						onClick={handleCancel}>
                     Cancel
                  </Button>
        </DialogContent>
      </Dialog>	
      </MuiPickersUtilsProvider>
  );
}

