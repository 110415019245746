import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import moment from "moment";
import distiAuth from 'disti-auth.js';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
}));

const nameSort = (a,b)=>{
    
  const nameA = (a.description || "").toUpperCase();
  const nameB = (b.description || "").toUpperCase();
  return nameA.localeCompare(nameB);
}
export default function ClassSelectMenu({currentSelectedId, allClasses, onSelected, ...params}) 
{    
  const [nowTime, setNowTime] = React.useState(moment().unix());
  React.useEffect(() => {
    let interval = null;
    interval = setInterval(() => {
		setNowTime( moment().unix() );
    }, 60000);
    return () => clearInterval(interval);
  });
  
  const {allowedActions, assignedMarkets} = React.useContext( distiAuth.AllowedActionsContext );

  const allClassesMemo = React.useMemo(()=>{
      // Assuming names and permissions are not changing often
     const assignedMarketsSet = new Set( assignedMarkets )
      
     return allClasses.filter((classItem)=>{
            return allowedActions.noMarketRestrictions || assignedMarketsSet.has( classItem.market)
     }).sort(nameSort)

  },[allClasses.length])
  
  
  const allActiveClasses = allClassesMemo.filter(classItem => (classItem.startDate <= nowTime && nowTime <= classItem.endDate));
  
  let allClassesById = {}
  if (allActiveClasses)
  {
      allActiveClasses.forEach((item)=>{
        allClassesById[item.id] = item  
      })
  }  
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedIndex, setSelectedIndex] = React.useState(1);

  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const currentClass = allActiveClasses.find((item)=>item.id == currentSelectedId) || null
  
  return (
    <div className={classes.root}>

    <Autocomplete
      id="combo-box-demo"
      value={currentClass}
      options={allActiveClasses}
      getOptionLabel={(option) => option.description}
      style={{ width: 300 }}
      onChange={(event, newInputValue) => onSelected(newInputValue?newInputValue.id:"")}
      renderInput={(params) => <TextField {...params} label="Facilitate Class" variant="standard" />}
    />
{/*    
      <FormControl {...params}>
        <InputLabel id="demo-simple-select-label">Facilitate Class</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={currentSelectedId}
          onChange={(event) => onSelected(event.target.value)}
        >
        {allClasses.map((item, index) => (
          <MenuItem key={item.id} value={item.id}>{item.description}</MenuItem>
          ))
        }
        </Select>
      </FormControl>
*/}
    </div>
  );
}