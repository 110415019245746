import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table-6";

import gql from "graphql-tag";
import { useQuery, useMutation, useSubscription } from "@apollo/client";

import Queries from "GraphQL/InstructorAccess.js"

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import People from "@material-ui/icons/People";
import PersonAdd from "@material-ui/icons/PersonAdd";
import Close from "@material-ui/icons/Close";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import EditIcon from "@material-ui/icons/Edit";
import HistoryIcon from '@material-ui/icons/History';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import SendIcon from '@material-ui/icons/Send';
import ListIcon from '@material-ui/icons/List';
import Select from '@material-ui/core/Select';
import SettingsIcon from '@material-ui/icons/Settings';
import SaveIcon from '@material-ui/icons/SaveAlt';
import CircularProgress from '@material-ui/core/CircularProgress';
import Chip from '@material-ui/core/Chip';
import BuildIcon from '@material-ui/icons/Build';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import SchoolIcon from '@material-ui/icons/School';
import MarketEdit from "views/Pages/MarketEdit";
import MapIcon from '@material-ui/icons/Map';
import AddIcon from '@material-ui/icons/Add';
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import SubscriptionHelpers from "GraphQL/SubscriptionHelpers.js"

import XLSX from 'xlsx';
import GetAppIcon from '@material-ui/icons/GetApp';
import Fab from '@material-ui/core/Fab';

import distiAuth from "disti-auth.js";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  }
};
const DefaultColumnFilter = ( { filter, onChange })  =>
{
  return (
	<input
	  value={filter ? filter.value : ''}
	  onChange={e => {
		  //console.log("Got onChange: "+ JSON.stringify(e.target.value));
		onChange(e.target.value || []) // Set undefined to remove the filter entirely
	  }}
	  placeholder={`🔍`}//Search ${count} records...`}
	/>
  )
 
}

const useStyles = makeStyles(styles);
  
export default () => {

  const userData = distiAuth.getUserData();

  const { loading: contentLoading, data: contentData } = useQuery(Queries.CONTENT.ALL);

  const [ deleteMarket ] = useMutation(Queries.MARKETS.DELETE);
  
  const [ modifyMarket, {loading: modifyMarketLoading, error: modifyMarketError} ] = useMutation(Queries.MARKETS.MODIFY);
  
  const [ createMarket ] = useMutation(Queries.MARKETS.CREATE);
    
  const { loading: queryLoading, data: queryData } = SubscriptionHelpers.useSubscribedMarketsQuery(); //useQuery(Queries.MARKETS.ALL); 
  
  const NoDataComponent = ()=>{ return ( queryLoading ? 
        <CircularProgress disableShrink style={{
            position: "absolute",
            top: "200px",
            // Center it left/right:
            left: "0px",
            right: "0px",
            marginLeft: "auto",
            marginRight: "auto"
        }} />: 
        <div style={{
            position: "absolute",
            top: "200px",
            // Center it left/right:
            textAlign: "center",
            left: "0px",
            right: "0px",
            marginLeft: "auto",
            marginRight: "auto"
        }}>No Markets Visible</div> )}  
        
  const [menuAnchorElement, setMenuAnchorElement] = React.useState(null);
  const [menuTargetData, setMenuTargetData] = React.useState(null);
  
  const [marketEditData, setMarketEditData] = React.useState(null);
  const [creatingNewMarket, setCreatingNewMarket] = React.useState(false);
    
  let reactTable = React.useRef(null);
  const [pageSize, setPageSize] = React.useState(null);

  let regionNameById = {};
  distiAuth.getRegionDataItems().forEach((i) => {
        regionNameById[i.id] = i.name;
    });
  const contentNameById = function(id)
  {
	  if (contentLoading || !contentData)
	  {
		  return "...";
	  }
	  let filteredItems = contentData.listContent.items.filter((val)=>{ 
			return val.id == id; 
		});
	  if (filteredItems.length === 0)
	  {
		  return "Unknown";
	  }
	  else
	  {
		return filteredItems[0].name;		  
	  }	  
  }

  const handleCreate = async(newData)=>
  {
      try{
        if (newData.createNew && !newData.id)
        {
            const newMarket = await createMarket();
            console.log("Created market: "+ JSON.stringify(newMarket));

            newData.id = newMarket.data.createMarket.pk;
        }
        
        //console.log("Got apply!" + JSON.stringify({pk:newData.id, name: newData.name, defaultRegion: newData.defaultRegion }))
                        
        modifyMarket({variables: {pk:newData.id, name: newData.name, defaultRegion: newData.defaultRegion, content: newData.content }});
      }
      catch(e)
      {
          console.log("Issue in handleCreate: "+e);
      }
  }
  let marketList = [];
  
  if (queryData)
  {
    marketList = queryData.listMarkets.items.map(item => {
			return {
				id: item.id,
				name: item.name,
                content:  item.content ? item.content.map((content) => contentNameById(content)) : "",
                defaultRegion: (item.defaultRegion && regionNameById[item.defaultRegion]) ? regionNameById[item.defaultRegion]  : (item.defaultRegion || "---"),
                actions: 
                  <>
                    <Button key={item.id}
						round
                        size="sm"
						color="primary" 
						onClick={(event) => { 
                            setMenuAnchorElement(event.currentTarget);
                            setMenuTargetData(item);
                        }}
					  >
						<BuildIcon/>
                        <ArrowDropDownIcon/>
					</Button>
                  </>

			};
	   }
	   )	          
  }
  const doDelete = (entry)=>
  {
      if (entry.id)
      {
          const yes = window.confirm("Are you sure you want to delete the Market: "+entry.name+"?");
          
          if (yes)
          {
            deleteMarket({variables: {"pk": entry.id}});
          }
      }
  }
  const dumpAllMarketData = ()=>
  {
      const marketsData = ((queryData && queryData.listMarkets ) ? queryData.listMarkets.items : []).map(item=>
      {
          return { 
              name: item.name,
              content: (item.content || []).map(content => contentNameById(content)).join("\r\n"),
              defaultRegion: item.defaultRegion 
          }
      })
      const book =  XLSX.utils.book_new();
      const marketSheet = XLSX.utils.json_to_sheet(marketsData || []);
      XLSX.utils.book_append_sheet(book, marketSheet, "Markets");
      XLSX.writeFile(book, "market-data.xlsx");  

     }           
  
  const doCreate = ()=>
  {
      setCreatingNewMarket(true);
      setMarketEditData({})    
  }
  const handleCloseMenu = ()=>
  {
    setMenuAnchorElement(null);
    setMenuTargetData(null);
  }
  const classes = useStyles();
    return (
	<>
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="primary" icon>
		    <CardIcon color="primary">
              <MapIcon />
            </CardIcon>
            <h1 className={classes.cardIconTitle}>Markets
            <div style={{float:"right"}}>
              {distiAuth.showDownloadButtons()?<Button round color="primary" onClick={ ()=>{dumpAllMarketData();} }><GetAppIcon/>Export Table</Button>: null}
              <Button round color="primary" onClick={ ()=>{doCreate();} }><AddIcon fontSize="small" style={{marginRight:0}}/><MapIcon style={{marginLeft:0, marginRight:10}} />Add Market</Button>
            </div>
            </h1>
          </CardHeader>
          <CardBody>
          {Boolean(marketEditData)?
          <MarketEdit 
            open={true} 
            initialValue={marketEditData}
            createNew={creatingNewMarket}
            onApply={handleCreate}
            onClose={()=>setMarketEditData(null)}
          />:null}
          <Menu
                id="action-menu"
                anchorEl={menuAnchorElement}
                open={Boolean(menuAnchorElement)}
                onClose={handleCloseMenu}
              >
              <MenuItem onClick={()=>{
                      setCreatingNewMarket(false);
                      setMarketEditData(menuTargetData);
                      handleCloseMenu();
                  }}>Edit Market</MenuItem>
              <MenuItem onClick={()=>{
                      doDelete(menuTargetData);
                      handleCloseMenu();
                  }}>Delete Market</MenuItem>
        </Menu>                        
            <ReactTable ref={(r)=>{reactTable.current = r}}
              data={marketList}
              filterable
 			  style={{
				height: "calc( 100vh - 276px)" 
			  }}
			  defaultFilterMethod={(filter, row, column) => {
				const id = filter.pivotId || filter.id
				if (row[id] !== undefined )
				{
					const haystack =(String(row[id])).toLowerCase();
					const needle = String(filter.value).toLowerCase();
					return haystack.includes(needle);
				}
				return true;
			  }}
              columns={[
                {
                  Header: "Name",
                  accessor: "name",
				  Filter: DefaultColumnFilter,
                  width: 350,
                },
                {
                  Header: "Content",
                  accessor: "content",
                  Cell: (row)=> row.value ? row.value.map((contentName) => {
                    return (<Chip 
                              style={{margin: "2px"}}
                              size="small"
                              key={"content_chip"+contentName} 
                              label={contentName}
                            />)
                  }) : "",
                  sortable: false,
                  filterable: false,
                },
                {
                  Header: "Default Region",
                  accessor: "defaultRegion",
                  width: 200
                },
                {
                  Header: "Actions",
                  accessor: "actions",
                  sortable: false,
                  filterable: false,
                  width: 150
                }				
              ]}
			  defaultSorted={[
				{
				  id: "name",
				  desc: false
				}
			  ]}              
			  onFilteredChange={() => {
			    setPageSize(reactTable.current.getResolvedState().sortedData.length);
			  }}			  
              pageSize={ pageSize != null ? pageSize : marketList.length }
			  
              showPaginationTop={false}
              showPaginationBottom={false}
              className="-striped -highlight"
              NoDataComponent={NoDataComponent}
            />

        </CardBody>
        </Card>
      </GridItem>
    </GridContainer>

	</>  );
}
