import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import LinearProgress from '@material-ui/core/LinearProgress';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import LiveTvIcon from '@material-ui/icons/LiveTv';
import CardActionArea from '@material-ui/core/CardActionArea';
import MessageIcon from '@material-ui/icons/Message';
import Badge from '@material-ui/core/Badge';
import kinesisDataOnlySupport from 'components/LiveClassModal/KinesisDataOnlySupport.js'
import distiAuth from 'disti-auth.js'
import instructorChatData from 'components/FacilitatorDashboard/InstructorChatData.js'

import LiveStudentDetail from "components/LiveClassModal/LiveStudentDetail.js";

import defaultContentThumbnail from "../../thumbnail_placeholder.jpg";

const useStyles = makeStyles({
  root: {
    minWidth: "275px",
    //minHeight: "415px"
  },
  facilitator: {
    minWidth: "175px",
    //minHeight: "415px"
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
    marginTop: 14,
  },
  contentThumbnail: {
    maxHeight: '75px',
    maxWidth: '133px',
    objectFit: 'contain',
    float: "right",
    //width: "unset",    
    margin: "5px"
  },  
  elipsisedContent: {
    whiteSpace: "nowrap",
//    position: "relative",
    overflow: "hidden", 
    textOverflow: "ellipsis",

  },
  userThumbnail: {
    height: '75px',
    maxWidth: '133px',
    objectFit: 'contain',
    float: "left",
    //width: "unset",    
    margin: "5px"
  },  
});
const ThinLinearProgress = withStyles({
  root: {
    height: 10,
    borderRadius: 10,
  },
  bar: {
    borderRadius: 10,
  },
  colorPrimary: {
    backgroundColor: '#a4a8ab',
  },
  barColorPrimary: {
    backgroundColor: '#40bf40',
  },
})(LinearProgress);


export default function LiveStudentDashboardCard({item, studentActive, onSelected, showWebcam}) {
  const classes = useStyles();

/*
 email: studentId,
		  fullName: studentData ? studentData.fullName : "Unknown",
		  progress: statusData && statusData.percentComplete ? (                   
                    <ThickLinearProgress
                        variant="determinate"
                        value={ 100.0 * statusData.percentComplete}
                        style={{ width: "100%", display: "inline-block" }}
                    />) : "---",
		  //currentClass: statusData && statusData.currentClass ? statusData.currentClass : "---",
		  trainingMode: statusData && statusData.trainingMode ? statusData.trainingMode : "---",
		  currentLesson: statusData && statusData.currentLesson ? statusData.currentLesson : "---",
		  elapsedTime: elapsedTime,
          currentContent: statusData && statusData.currentContent ? statusData.currentContent : "---",
          connectedTime: connectedTime,
          countryName: statusData && statusData.countryCode && (statusData.countryCode in countryNames) ? countryNames[statusData.countryCode] : "--",
          regionName: statusData && statusData.currentRegion && regionNames && (statusData.currentRegion in regionNames) ? regionNames[statusData.currentRegion] : "--"
          */

  let textEntry = (tooltip, text)=>
  {
      return (<Tooltip title={tooltip +": "+text}>
        <Typography className={[classes.pos, classes.elipsisedContent]} 
        color="textSecondary">
            {text}
        </Typography>
        </Tooltip>);
  }
  //      <Badge badgeContent={4} color="primary">

  
 
  const {currentData, sendData, allCount, unreadCount, connected, clearUnread} = instructorChatData.useChatData({passive: !studentActive, studentName: item.email || "NOBODY", classId: "TODO", pollInterval:5000})

 
  
  const smallMode = false
  return (
        <div key={item.email} style={{position: "relative"}}>
            <Badge 
                badgeContent={unreadCount} 
                color="error" 
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }} 
                style={{position: "absolute", right:"0px", top:"0px"}}>
            </Badge>
  
    <Card className={item.facilitator ? classes.facilitator : classes.root} elevation={3}>
    <CardActionArea disabled={false/*!studentActive*/} onClick={(e)=>{
            
            e.preventDefault() 
            onSelected(item.email)
        }} >
      <CardContent >
        
            <Tooltip title={item.email + " " + item.fullName}>
            <Typography variant="h6" component="h2" className={[classes.elipsisedContent]}>
                {item.fullName}
            </Typography>        
            </Tooltip>
            <Tooltip title={"Lesson Progress: "+Math.round(item.progressPercent)+"%"}>
                <ThinLinearProgress
                    variant="determinate"
                    value={ item.progressPercent || 0}
                    style={{ width: "100%", display: "inline-block" }}
                />
            </Tooltip>
        
            <CardMedia
                component="img"
                className={classes.contentThumbnail}
                image={studentActive ? item.contentThumbnail : defaultContentThumbnail}          />      
        {smallMode ? "" :[
            textEntry("Training Mode", item.trainingMode),
            textEntry("Content", item.currentContent),
            textEntry("Current Lesson Name", item.currentLesson),
            textEntry("Elapsed Time in Current Lesson", item.elapsedTime),
            <hr/>]
        }
            <CardMedia
                component="img"
                className={classes.userThumbnail}
                image={item.userThumbnail}
            />
        {smallMode ? "" :    
            <div style={{textAlign: "right"}}>
                {textEntry("Connected Time", item.connectedTime)}
                {textEntry("Connected From Country", item.countryName)}
                {textEntry("Dealer", item.dealer)}            
            </div>
        }
      </CardContent>
    </CardActionArea>
    </Card>
        </div>
  );
}
