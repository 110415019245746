import Queries from "GraphQL/InstructorAccess.js"
import React from "react"
import { useQuery, useMutation, useSubscription } from "@apollo/client";

export default {
    
    useSubscribedUserStatusQuery: ()=>
    {
        const { loading, data, subscribeToMore } = useQuery(Queries.USERS.STATUS, {returnPartialData: true}); 

         React.useEffect(()=>
          {
              if (subscribeToMore)
              {

                return subscribeToMore({
                    document: Queries.USERS.STATUS_SUBSCRIPTION,
                    updateQuery: (cachedDataOrig,{subscriptionData})=>{
                        // After switching to WebSocket, the cachedData already has our changes, so...
                        const cachedData = JSON.parse(JSON.stringify(cachedDataOrig))
                        return cachedData
                    }
                })

              }
          },[subscribeToMore])    

        return { loading, data }
    },
    useSubscribedClassQuery: ()=>
    {
        const { loading, data, subscribeToMore } = useQuery(Queries.CLASSES.ALL); 

         React.useEffect(()=>
          {
              if (subscribeToMore)
              {
                  return subscribeToMore({
                      document: Queries.CLASSES.ALL_SUBSCRIPTION,
                      updateQuery: (cachedDataOrig,{subscriptionData})=>{
                      
                        if (!subscriptionData.data) return cachedDataOrig;
                        
                        const cachedData = JSON.parse(JSON.stringify(cachedDataOrig))
                        subscriptionData = JSON.parse(JSON.stringify(subscriptionData))
                        
                        if (subscriptionData.data.onClassChange)
                        {
                            // The id->pk mapping gets lost with the subscriptions
                            subscriptionData.data.onClassChange.id = subscriptionData.data.onClassChange.id || subscriptionData.data.onClassChange.pk
                                
                            // If one already exists, replace it.
                            let filteredItems = [];
                            if (cachedData.listClasses)
                            {
                                if (subscriptionData.data.onClassChange.deleted)
                                {
                                    filteredItems = cachedData.listClasses.items.filter((val)=>{ 
                                        return val.id !== subscriptionData.data.onClassChange.id; 
                                    });
                                }
                                else
                                {
                                    filteredItems = [...cachedData.listClasses.items];
                                    let found = filteredItems.find((val) => val.id === subscriptionData.data.onClassChange.id);
                                    if (found )
                                    {
                                        Object.assign(found,subscriptionData.data.onClassChange);
                                    }
                                    else
                                    {
                                        filteredItems.push(subscriptionData.data.onClassChange)
                                    }				
                                }			
                            }


                            // If the data argument isn't a copy, this will fail to trigger an update
                            cachedData.listClasses.items = filteredItems
                        }
                        
                        return cachedData
                      }})
              }
          },[subscribeToMore])    

        return { loading, data }
    },
    useSubscribedStudentsQuery: ()=>
    {
        const { loading, data, subscribeToMore } = useQuery(Queries.STUDENTS.ALL); 
            
        React.useEffect(()=>
        {
          if (subscribeToMore)
          {
              return subscribeToMore({
                  document: Queries.STUDENTS.ALL_SUBSCRIPTION,
                  updateQuery: (cachedData,{subscriptionData})=>{
                      
                        if (!subscriptionData.data) return cachedData;

                        let rval = JSON.parse(JSON.stringify(cachedData))
                        //rval.listStudents = [...rval.listStudents]
                        
                        // The subscribeToMore doesn't do the pk->id conversion correctly.
                        const onStudentsChange = {
                                ...subscriptionData.data.onStudentsChange, 
                                id: subscriptionData.data.onStudentsChange.id || subscriptionData.data.onStudentsChange.pk
                        }
                        // If one already exists, replace it.
                        rval.listStudents.items = rval.listStudents.items.filter((val)=>{ 
                            return val.id != onStudentsChange.id; 
                        });
                        
                        if (!onStudentsChange.deleted)
                        {
                            rval.listStudents.items.push(onStudentsChange)
                        }
                        
                        return rval 
              }})
          }
        },[subscribeToMore])

        return { loading, data }  
    },
    useSubscribedMarketsQuery:()=>
    {
        const { loading, data, subscribeToMore } = useQuery(Queries.MARKETS.ALL); 
  
        React.useEffect(()=>
        {
          if (subscribeToMore)
          {
              return subscribeToMore({
                  document: Queries.MARKETS.ALL_SUBSCRIPTION,
                  updateQuery: (cachedDataOrig,{subscriptionData})=>{

                        if (!subscriptionData.data) return cachedDataOrig
                        
                        const cachedData = JSON.parse(JSON.stringify(cachedDataOrig))      
                        
                        // Subscription looses pk=>id mapping
                        subscriptionData.data.onMarketChange.id = subscriptionData.data.onMarketChange.id || subscriptionData.data.onMarketChange.pk
                        
                        // If one already exists, replace it.
                        const filteredItems = cachedData.listMarkets.items.filter((val)=>{ 
                            return val.id != subscriptionData.data.onMarketChange.id; 
                        });
                        
                        if (!subscriptionData.data.onMarketChange.deleted)
                        {
                            filteredItems.push(subscriptionData.data.onMarketChange)
                        }
                            
                        cachedData.listMarkets.items = filteredItems
                        
                        return cachedData
                    }
              })
            }
        },[subscribeToMore])

        return { loading, data }       
    },
    useSubscribedTrainersQuery:()=>
    {
        const { loading, data, subscribeToMore } = useQuery(Queries.TRAINERS.ALL); 
  
        React.useEffect(()=>
        {
          if (subscribeToMore)
          {
              return subscribeToMore({
                  document: Queries.TRAINERS.ALL_SUBSCRIPTION,
                  updateQuery: (cachedDataOrig,{subscriptionData})=>{

                        if (!subscriptionData.data) return cachedDataOrig
                        
                        const cachedData = JSON.parse(JSON.stringify(cachedDataOrig))
                        subscriptionData = JSON.parse(JSON.stringify(subscriptionData))
                        
                        subscriptionData.data.onTrainerChange.id = subscriptionData.data.onTrainerChange.id || subscriptionData.data.onTrainerChange.pk
                        
                        const filteredItems = cachedData.listTrainers.items.filter((val)=>{ 
                            return val.id != subscriptionData.data.onTrainerChange.id; 
                        });
                        
                        if (!subscriptionData.data.onTrainerChange.deleted)
                        {
                            filteredItems.push(subscriptionData.data.onTrainerChange)
                        }
                            
                        
                        cachedData.listTrainers.items = filteredItems
                        
                        return cachedData
                    }
              })
            }
        },[subscribeToMore])

        return { loading, data }       
    }    

}
