import React from "react";

//import {Widget, addResponseMessage, toggleWidget, isWidgetOpened, toggleInputDisabled, addUserMessage } from 'react-chat-widget';
import ChatBox from 'components/FacilitatorDashboard/ChatBox.js'
import 'react-chat-widget/lib/styles.css';
import distiAuth from 'disti-auth.js'
//import KinesisView from './KinesisViewViewer.js';
import Badge from '@material-ui/core/Badge';
import kinesisDataOnlySupport from 'components/LiveClassModal/KinesisDataOnlySupport.js'
import instructorChatData from 'components/FacilitatorDashboard/InstructorChatData.js'

const nextMessageId = {value: 99999999}

const getInitials = (fullName)=>
{
    return fullName.split(' ').reduce((accum,item)=>{return accum+item.charAt(0)},"")
}
const lastReadCountById = {}
export default ({studentId})=>
{    

    const {currentData, sendData, allCount, unreadCount, connected, clearUnread} = instructorChatData.useChatData({passive: true, studentName: studentId || "NOBODY", classId: "TODO", pollInterval:5000})
    
    const sendMessage = (newMessage)=>{
        const newMessageObject = {
            messageId: nextMessageId.value++,
            who: distiAuth.getUsername(), 
            whoFull: getInitials(distiAuth.getUserData().fullName || ""),
            whoType: "INSTRUCTOR", 
            what: newMessage,
            when: Date.now(),
        }
        
        return sendData(JSON.stringify(newMessageObject))        
    };
    //const [connected, sendUserMessage] = kinesisDataOnlySupport.useChatDataConnector({studentId})
    
    React.useEffect(()=>{
        
        const handle = window.setTimeout(clearUnread, 2000)
        return ()=>{ window.clearTimeout(handle) }
        
    },[unreadCount])
    return (<div  >
                <Badge 
                badgeContent={unreadCount} 
                color="error" 
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }} 
                style={{position: "absolute", right:"0px", top:"0px"}}>
            </Badge>


        <ChatBox disabled={!connected} currentData={currentData} allCount={allCount} onSend={sendMessage}/>

        </div>)
        
        
    
}