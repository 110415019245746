import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";

// @material-ui/icons
import Face from "@material-ui/icons/Face";
import Email from "@material-ui/icons/Email";
// import LockOutline from "@material-ui/icons/LockOutline";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";

import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";

const useStyles = makeStyles(styles);


var appstreamEmbed;

function launchAppStreamSession(streamingURL) {
    
    destroyAppStreamFrame();

    const AppStream = window.AppStream;
    var userInterfaceConfig = {};
    userInterfaceConfig[AppStream.Embed.Options.HIDDEN_ELEMENTS] = [AppStream.Embed.Elements.TOOLBAR];
    /*
        AppStream.Embed.Elements.FULLSCREEN_BUTTON,
        AppStream.Embed.Elements.END_SESSION_BUTTON,
        AppStream.Embed.Elements.FILES_BUTTON,
        AppStream.Embed.Elements.COPY_LOCAL_BUTTON, 
        AppStream.Embed.Elements.PASTE_REMOTE_BUTTON];
    */
    if (streamingURL && streamingURL.length > 0) {
        var appstreamOptions = {
            sessionURL: streamingURL,
            userInterfaceConfig: userInterfaceConfig
        };
        appstreamEmbed = new AppStream.Embed("appstream-container", appstreamOptions);
        appstreamEmbed.addEventListener(AppStream.Embed.Events.SESSION_STATE_CHANGE, updateSessionStateCallback);
        appstreamEmbed.addEventListener(AppStream.Embed.Events.SESSION_INTERFACE_STATE_CHANGE, updateUserInterfaceStateCallback);
        appstreamEmbed.addEventListener(AppStream.Embed.Events.SESSION_ERROR, errorCallback);
        appstreamEmbed.addEventListener(AppStream.Embed.Events.EVENT_SESSION_RESIZE, function(event){console.log("resize event: "+JSON.stringify(event))});
        
    } else {
        alert("Invalid URL");
    }
}

function destroyAppStreamFrame() {
    if (appstreamEmbed) {
        appstreamEmbed.destroy();
        appstreamEmbed = null;
       //TODO: delete appstreamEmbed;
    }

}

function isAlertActive()
{
    try
    {
        // Look in side the iFrame to see if it has the Retry popup
        let iframeDoc = document.getElementById("appstream-streaming-session").contentWindow.document;
        let alerts = iframeDoc.getElementsByClassName("alert");
        for (let a in alerts)
        {
            if (alerts[a].offsetParent)
            {
                // There is an active alert
                return true;       
            }
        }
    }
    catch(e)
    {
        console.log("Problem in isAlertActive: "+e);
    }
    return false;
}
let secondsRemainingTillRetry = 1 * 60; 
function doAutomaticRetry()
{
    if (isAlertActive())
    {
        let theiframe = document.getElementById("appstream-streaming-session");
        let retryButton = theiframe.contentWindow.document.getElementById("modal-alert-retry");
        
        retryButton.innerHTML = "Retrying in "+secondsRemainingTillRetry+" seconds...";
        window.setTimeout(function(){ 
            if (secondsRemainingTillRetry <= 0)
            {
                secondsRemainingTillRetry = 1 * 60; 
                theiframe.contentWindow.location.reload();
            }
            else
            {
                secondsRemainingTillRetry -= 1;
                doAutomaticRetry();
            }
        }, 1000);
    }
}
function handleUnknownState()
{
    window.setTimeout(function(){doAutomaticRetry();console.log("isAlertActive: "+isAlertActive());},2000);
}
function updateSessionStateCallback(event) {
    let status = event[window.AppStream.Embed.EventParams.STATUS];
    switch(window.AppStream.Embed.SessionStatus[status]) {
        case window.AppStream.Embed.SessionStatus.Unknown:
            handleUnknownState();
            break;
        case window.AppStream.Embed.SessionStatus.Started:

            console.log("AppStream started.")
            /*
            document.getElementById("appstream-url-form").style.visibility = 'collapse';
            document.getElementById("appstream-url-form").style.display = 'none';
            document.getElementById("in-stream-functions").style.visibility = 'visible';
            document.getElementById("appstream-container").style.visibility = 'visible';
            document.getElementById("in-stream-functions").style.display = 'block';
            document.getElementById("keyboard-events").addEventListener("keypress", sendKeyboardEvent);
            document.getElementById("keyboard-events").addEventListener("keydown", sendKeyboardEvent);
            document.getElementById("keyboard-events").addEventListener("keyup", sendKeyboardEvent);
            */
            break;
        default:
            break;
    }
    console.log("embed-sample.html listened to updateSessionState event: " + JSON.stringify(event));
}

function updateUserInterfaceStateCallback(event) {
    console.log("embed-sample.html listened to updateUserInterfaceState event: " + JSON.stringify(event));
}

function errorCallback(event) {
    console.log("embed-sample.html listened to appstreamEmbedError event: " + JSON.stringify(event));
}


export default ({ streamingURL }) => {
  const classes = useStyles();
  
  React.useEffect(() => {
      if (streamingURL)
      {
        launchAppStreamSession(streamingURL);
      }
  }, []);
//    <div style={{margin:"0px", padding:"0px", overflow:"hidden"}}>
//    </div>
  
  return (
        <div id="appstream-container" style={{pointerEvents:"none" /* blocks mouse interactions*/, position:"absolute", left: "0", top:"0", width: "100%", height: "100%", margin:0, overflow:"hidden"}}> </div>         
  );
}
