import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table-6";

import axios from "axios"

import gql from "graphql-tag";
import { useQuery, useMutation, useSubscription } from "@apollo/client";
import Queries from "GraphQL/InstructorAccess.js"
import Autocomplete from '@material-ui/lab/Autocomplete';


// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import People from "@material-ui/icons/People";
import PersonAdd from "@material-ui/icons/PersonAdd";
import Close from "@material-ui/icons/Close";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import EditIcon from "@material-ui/icons/Edit";
import HistoryIcon from '@material-ui/icons/History';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import SendIcon from '@material-ui/icons/Send';
import ListIcon from '@material-ui/icons/List';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import SettingsIcon from '@material-ui/icons/Settings';
import SaveIcon from '@material-ui/icons/SaveAlt';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import VideoLibraryIcon from '@material-ui/icons/VideoLibrary';
import XLSX from 'xlsx';
import moment from 'moment';
import LinearProgress from '@material-ui/core/LinearProgress';

import MonthPicker from 'react-month-picker'

import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";

import distiAuth from "disti-auth.js";

import VideoHistory from 'views/Pages/VideoHistory.js'

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  }
};
const DefaultColumnFilter = ( { filter, onChange })  =>
{
  return (
	<input
	  value={filter ? filter.value : ''}
	  onChange={e => {
		  //console.log("Got onChange: "+ JSON.stringify(e.target.value));
		onChange(e.target.value || []) // Set undefined to remove the filter entirely
	  }}
	  placeholder={`🔍`}//Search ${count} records...`}
	/>
  )
 
}

class MonthBox extends React.Component {
    constructor(props, context) {
      super(props, context)
      this.state = {
          value: this.props.value || 'N/A',
      }
      this._handleClick = this._handleClick.bind(this);
    }

    componentWillReceiveProps(nextProps){
      this.setState({
          value: nextProps.value || 'N/A',
      })
    }

    render() {
      return (
        <div className="box" onClick={this._handleClick}>
            <label>{this.state.value}</label>
        </div>
      );
    }

    _handleClick(e) {
      this.props.onClick && this.props.onClick(e);
    }
}
//const s3USEast = new AWS.S3({region: "us-east-1"}); 

const useStyles = makeStyles(styles);
const years = []
let y = parseInt( new Date().getFullYear() );
const thisYearMonth = {year: y, month: 1+ new Date().getMonth()}
const firstYear = 2020
const lastYear = y + 1
for(let i=firstYear; i <= lastYear; i++)
{
    years.push(i)
}
  
export default () => {

const userData = distiAuth.getUserData();
const defaultResponsibleParty = "results";
	   
       
  const { data: marketQueryData } = useQuery(Queries.MARKETS.ALL); 
       
  const {allowedActions, assignedMarkets} = React.useContext( distiAuth.AllowedActionsContext );
       
  const [ updateTrigger, setUpdateTrigger ] = React.useState(1)

  const [ historyData, setHistoryData ] = React.useState({data:null});

  const [ retryReportCount, setRetryReportCount ] = React.useState(5);
  const [currentYearMonth, setCurrentYearMonth ] = React.useState(thisYearMonth);
  
  const [showResponsibleParty, setShowResponsibleParty ] = React.useState(defaultResponsibleParty);

  const [marketIdsToShow, setMarketIdsToShow ] = React.useState([]);
  
  const pickAMonthRef = React.useRef();
  
  let reactTable = React.useRef(null);
  const [pageSize, setPageSize] = React.useState(null);

  const [showVideoManagement, setShowVideoManagement] = React.useState(false);
  
  const [showLessonReportDialog, setShowLessonReportDialog] = React.useState(false);
  const [buildingLessonReport, setBuildingLessonReport] = React.useState(false);
  const [lessonReportDownloadUrl, setLessonReportDownloadUrl] = React.useState("");
  
  const historyDataIsAvailableAndValid = ()=>
  {
      return !!(historyData.data && historyData.request);
  }
  React.useEffect(()=>
  {
      
  },[retryReportCount]);
    
  let allAllowedMarkets = [];
  let marketDataById = {};
  if (marketQueryData && marketQueryData.listMarkets.items)
  {
      const assignedMarketsSet = new Set(assignedMarkets);
      allAllowedMarkets = marketQueryData.listMarkets.items.filter((item)=>{
          return allowedActions.noMarketRestrictions || assignedMarketsSet.has(item.id)          
      })

      // Special ALL id for requesting all markets
      if (allowedActions.noMarketRestrictions)
      {
          allAllowedMarkets = [{id:"ALL", name:"All Markets"}].concat(allAllowedMarkets)
      }
      
      allAllowedMarkets.forEach((item)=>
      {
         marketDataById[item.id] = item;        
      });
  }
  
  let dataToShow = [];
  if (historyData.data)
  {
      console.log("dataToShow is now: "+JSON.stringify(dataToShow));
	   dataToShow = historyData.data.map(item => {
           
			return {
				id: item.user_session_id,
				region: item.region,
                sessionDurationMinutes: parseInt(parseInt(item.session_duration_in_seconds) / 60),
                userId: item.user_id,
                responsibleParty: item.responsibleParty,
                sessionStartTime: item.session_start_time,
                marketName: item.marketName
			};
	   });	   
  }
  const generateLessonReport = async()=>
  {
      let month = parseInt(currentYearMonth.month)
      month = ((month < 10)?"0":"")+month
      
      const secondsInADay = 60 * 60 * 24;
      // We are including an extra day on the ends because the filter is just to make sure we don't cut anybody off due to time zones.  Expectations can be varied.
      let startOfMonth = moment({ year: currentYearMonth.year, month: parseInt(month)-1, date: 1}).startOf("month").unix() - secondsInADay;  
      let endOfMonth = moment({ year: currentYearMonth.year, month: parseInt(month)-1, date: 1}).endOf("month").unix() + secondsInADay;   
        

      const reportData = { 
        data: null, 
        request: {
          responsibleParty:showResponsibleParty,
          marketIds: marketIdsToShow,
          startTimeEpoch: startOfMonth,
          endTimeEpoch: endOfMonth,
        }, 
        name: "Lessons_"+showResponsibleParty+"_"+currentYearMonth.year+"_"+month+
                "_as_of_"+(new Date()).toUTCString()+
                (allowedActions.noMarketRestrictions && marketIdsToShow.includes("ALL")?" complete":" market-filtered")        
      };
      try
      {
        setBuildingLessonReport(true);
        setShowLessonReportDialog(true);
        setLessonReportDownloadUrl("");
        const urlToDownload = await distiAuth.getLessonHistoryByTime(reportData.request);
        
        if (urlToDownload)
        {
            setLessonReportDownloadUrl(urlToDownload);
        }
        else
        {
            console.log("Problem with getLessonHistoryByTime") 
        }
      }
      catch(e)
      {
          console.log("Problem getting lesson report data: "+e);
          //setHistoryData({error: "Unable to get report data, please try again.", data: null, request: reportData.request, name: reportData.name });            
      }              
      setBuildingLessonReport(false);
  }
  // Don't await for this... it can take a while.
  const generateUsageReport = async()=>
  {
      let month = parseInt(currentYearMonth.month)
      month = ((month < 10)?"0":"")+month
      const reportData = { 
        data: null, 
        request: {
          month:""+currentYearMonth.month, 
          year:""+currentYearMonth.year, 
          responsibleParty:showResponsibleParty,
          marketIds: marketIdsToShow,
        }, 
        name: "VirtualClassroom_"+showResponsibleParty+"_"+currentYearMonth.year+"_"+month+
                "_as_of_"+(new Date()).toUTCString()+
                (allowedActions.noMarketRestrictions && marketIdsToShow.includes("ALL")?" complete":" market-filtered")        
      };
      try
      {
        
        // Zero out the history while we get it
        setHistoryData(reportData);            
        
        reportData.data = await distiAuth.getAccessHistoryByYearMonthForResponsibleParty(reportData.request);
        // Add the market name
        reportData.data.forEach(item=>
        {
            const marketData = marketDataById[item.marketId]
            item.marketName = marketData ? marketData.name : "---"
        })
        
        // Set state ignores if itst the same object
        setHistoryData({...reportData});
        
        console.log("Got getAccessHistoryByYearMonthForResponsibleParty:"+JSON.stringify(reportData));            
      }
      catch(e)
      {
          console.log("Problem getting report data: "+e);
          setHistoryData({error: "Unable to get report data, please try again.", data: null, request: reportData.request, name: reportData.name });            
      }        
  }
  
  const downloadUsageReport = async()=>
  {
      if (!historyData.data)
      {
          await generateUsageReport();
      }
      const sheet = XLSX.utils.json_to_sheet(historyData.data || [],{
          header:[ // Control the order of the entries
            "user_session_id",
            "region",
            "session_start_time",
            "session_duration_in_seconds",
            "user_id",
            "connected_at_least_once",
            "year",
            "month",
            "day",
            "responsibleParty",
            "marketId",
            "marketName"
            ]});
      const book =  XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(book, sheet, showResponsibleParty);
      XLSX.writeFile(book, historyData.name+".xlsx");          
      
  }
  
  const handleAMonthChange = (year, month)=>
  {
      setCurrentYearMonth({year:year, month:month});
      
      console.log("handleAMonthChange: "+year+" "+month);
  }
  const handleAMonthDissmis = (val)=>
  {
      console.log("handleAMonthDissmis: "+val);
  }
  const pickerLang = {
            months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            from: 'From', to: 'To',
        }
  const makeText = m => {
            if (m && m.year && m.month) return (pickerLang.months[m.month-1] + ' ' + m.year)
            return '?'
        }        
  const handleClickMonthBox = (e)=>
  {
      pickAMonthRef.current.show();
  }
  const responsiblePartyChanged = function(newValue)
  {
      setShowResponsibleParty(newValue);
  }
  const handleCancel = ()=>
  {
      setHistoryData({data:null});
  }
  const classes = useStyles();
    return (
	<>
    <Dialog open={showVideoManagement} onClose={()=>{setShowVideoManagement(false)}} fullWidth={ true } maxWidth={false} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">
            <CloseIcon style={{float:"right"}} fontSize="large" onClick={()=>setShowVideoManagement(false)}/>
        </DialogTitle>
        <DialogContent>
            <VideoHistory/>
        </DialogContent>
    </Dialog>
    
    <GridContainer xs={12}>
      <GridItem xs={6}>
        <Card>
          <CardHeader color="primary" icon>
		    <CardIcon color="primary">
              <SettingsIcon />
            </CardIcon>
            <h1 className={classes.cardIconTitle}>Utilities</h1>
          </CardHeader>
          <CardBody>
  </CardBody>
  </Card>
  {!allowedActions.viewStudentUsage ? "":(
  <>
    <Card>
      <CardHeader color="primary" icon>
        <h2 className={classes.cardIconTitle}>Generate Reports</h2>
      </CardHeader>
      <CardBody>
            <MonthPicker
                        
                        ref={pickAMonthRef}
                        lang={pickerLang}
                        years={years}
                        value={currentYearMonth}
                        onChange={handleAMonthChange}
                        onDismiss={handleAMonthDissmis}
                    >
                      <MonthBox value={makeText(currentYearMonth)} onClick={handleClickMonthBox} />
            </MonthPicker>
                <br/>
                {marketQueryData && marketQueryData.listMarkets.items ?
                      <Autocomplete
                        multiple
                        disabled={false}
                        disableClearable={true}
                        id="tags-standard"
                        options={allAllowedMarkets.map((i)=>i.id)}
                        getOptionLabel={(id) => {return marketDataById[id] ? marketDataById[id].name : "---"}}
                        value={marketIdsToShow}
                        onChange={(event, newValue)=>{
                            
                            if (newValue )
                            {
                                let addedAll = !marketIdsToShow.includes("ALL") && newValue.includes("ALL")
                                if (addedAll)
                                {
                                    newValue = ["ALL"]                                    
                                }
                                else if (newValue.length > 1)
                                {
                                    // Drop ALL if we have more
                                    newValue = newValue.filter(item => item !== "ALL")
                                }                                
                            }
                            setMarketIdsToShow(newValue);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="standard"
                            label="Markets"
                          />
                        )}
                      />            
                    : "Loading..."}    
                {/*<Select
                        style = {{width:"20%", textAlign:"center"}}
                          value={showResponsibleParty || ""}
                          onChange={(e)=>{responsiblePartyChanged(e.target.value)}}
                        >
                        { userData.responsibleParties.map(item=>{return (<MenuItem key={item} value={item}>{item}</MenuItem>)})}
                </Select>
                */}
                <br/>
                <Button 
                    style={{float:"right"}/*position:"absolute", right:"20px", bottom:"5px"*/}
                    round 
                    color="primary" 
                    disabled={!marketIdsToShow.length}
                    onClick={()=>{generateUsageReport()}}
                  >
                    Generate Usage Report<ListIcon />						
                </Button>
                <Button 
                    style={{float:"left"}/*position:"absolute", right:"20px", bottom:"5px"*/}
                    round 
                    color="primary" 
                    disabled={!marketIdsToShow.length || buildingLessonReport}
                    onClick={()=>{generateLessonReport()}}
                  >
                  {buildingLessonReport?"Generating Lesson Report...": "Generate Lesson Report"}<ListIcon />						
                </Button>
  </CardBody>
  </Card>
  {allowedActions.exportVideos?(
    <Card>
      <CardHeader color="primary" icon>
        <h2 className={classes.cardIconTitle}>Manage Videos</h2>
      </CardHeader>
      <CardBody>
                
                <Button 
                    style={{float:"right"}/*position:"absolute", right:"20px", bottom:"5px"*/}
                    round 
                    color="primary" 
                    disabled={false}
                    onClick={()=>{setShowVideoManagement(true)}}
                  >
                    Manage Videos <VideoLibraryIcon />		
                    
                </Button>
  </CardBody>
  </Card>
  ):null}
  </>
  )}
  
  </GridItem>
  </GridContainer>
  
          <Dialog open={showLessonReportDialog} onClose={()=>{setShowLessonReportDialog(false)}} fullWidth={ true } maxWidth={"sm"} aria-labelledby="form-dialog-title">
		<DialogTitle id="form-dialog-title">{lessonReportDownloadUrl?"Lesson Report is ready for download":"Generating Lesson Report"}<CloseIcon style={{position: "absolute",
    right: "25px", zIndex: "1"}} fontSize="large" onClick={()=>{setShowLessonReportDialog(false)}}/>
        </DialogTitle>
        <DialogContent>        
        <br/>
        <LinearProgress style={{width:"80%"}} value={lessonReportDownloadUrl?100:0} variant={lessonReportDownloadUrl?"determinate":"indeterminate" }/>
        <br/>
        
        <Button 
                    round 
                    color="primary" 
                    disabled={!lessonReportDownloadUrl}
                    onClick={()=>{
                        const a = document.createElement('a');
                        a.href = lessonReportDownloadUrl;
                        a.click();
                        setLessonReportDownloadUrl("")
                        setShowLessonReportDialog(false)
                    }}
                  >
                  Download CSV					
        </Button>
        </DialogContent>
        </Dialog>
        
        <Dialog open={!!(historyData.request)} onClose={handleCancel} fullWidth={ true } maxWidth={false} aria-labelledby="form-dialog-title">
		<DialogTitle id="form-dialog-title"><CloseIcon style={{position: "absolute",
    right: "25px", zIndex: "1"}} fontSize="large" onClick={handleCancel}/>
        </DialogTitle>
        <DialogContent>
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="primary" icon>
		    <CardIcon color="primary">
            <ListIcon/>
            </CardIcon>
            <h1 className={classes.cardIconTitle}>{historyData.request?(<Typography variant="h5" component="h5">{historyData.name}
                {historyData.data ? 
                (<Button 
                    style={{position:"absolute", right: "20px"}}
                    round 
                    color="primary" 
                    onClick={()=>{downloadUsageReport()}}
                  >
                  Save Report  <SaveIcon/>
                </Button>): ""}
            </Typography>):"Generating request..."}</h1>
          </CardHeader>
          <CardBody>
          {!historyData.data?(<h2 style={{textAlign: "center"}} >{historyData.error?historyData.error:"Loading..."}</h2>):(              
            <ReactTable ref={(r)=>{reactTable.current = r}}
              data={dataToShow}
              filterable
 			  style={{
				height: "calc( 100vh - 276px)" 
			  }}
			  defaultFilterMethod={(filter, row, column) => {
				const id = filter.pivotId || filter.id
				if (row[id] !== undefined )
				{
					const haystack =(String(row[id])).toLowerCase();
					const needle = String(filter.value).toLowerCase();
					return haystack.includes(needle);
				}
				return true;
			  }}
              columns={[
                { 
                    Header: "Start Time",
                    accessor: "sessionStartTime",
                },
                {
                  Header: "Region",
                  accessor: "region",
				  Filter: DefaultColumnFilter,
                },                
                {
                  Header: "User",
                  accessor: "userId",
				  Filter: DefaultColumnFilter,
                },
                {
                  Header: "Duration (minutes)",
                  accessor: "sessionDurationMinutes",
				  Filter: DefaultColumnFilter,
                },
                {
                  Header: "Market",
                  accessor: "marketName",
				  Filter: DefaultColumnFilter,
                }/*,				
                {
                  Header: "Responsible Party",
                  accessor: "responsibleParty",
				  Filter: DefaultColumnFilter,
                }	*/			
              ]}
			  defaultSorted={[
				{
				  id: "sessionStartTime",
				  desc: false
				}
			  ]}              
			  onFilteredChange={() => {
			    setPageSize(reactTable.current.getResolvedState().sortedData.length);
			  }}			  
              pageSize={ pageSize != null ? pageSize : dataToShow.length }
			  
              showPaginationTop={false}
              showPaginationBottom={false}
              className="-striped -highlight"
          />)}
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
        </DialogContent>
        </Dialog>
	</>  );
}
