import React from 'react'
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import IconButton from '@material-ui/core/IconButton';
import VolumeOffIcon from '@material-ui/icons/VolumeOff';
import VolumeMuteIcon from '@material-ui/icons/VolumeMute';
import MicOffIcon from '@material-ui/icons/MicOff';
import MicIcon from '@material-ui/icons/Mic';
import CustomButton from "components/CustomButtons/Button.js";
import CallEndIcon from '@material-ui/icons/CallEnd';
import Draggable from 'react-draggable';
import kinesisViewSupport from "components/LiveClassModal/KinesisViewSupport.js"
import Typography from '@material-ui/core/Typography';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import globalHook from 'use-global-hook'
import Tooltip from '@material-ui/core/Tooltip';
import { useAnimate, useAnimateKeyframes, useAnimateGroup } from 'react-simple-animate';

const kinesisCaller = null

const useStyles = makeStyles({
  elipsisedContent: {
    whiteSpace: "nowrap",
    overflow: "hidden", 
    textOverflow: "ellipsis",
  },
});
const makeKinesisCaller = ()=>{
    
    //TODO
    
}

const actions = {
    call: (store, userId, fullName, classRegion)=>{
        if (!kinesisCaller)
        {
            makeKinesisCaller()
        }
        console.log("Calling "+userId)
        store.setState({...store.state, userId, fullName, classRegion, connecting: true, connected: false})
    },
    disconnect: (store)=>{
        console.log("Hanging up ")
        //if (store.state.userId || store.state.connected || store.state.connecting)
        {
            store.setState({...store.state, userId: "", fullName: "", classRegion: "", connected: false, connecting: false})
        }
    },

    // For internal use
    connected: (store)=>{
        store.setState({...store.state, connected: true, connecting: false})
    },
    disconnected: (store)=>{
        store.setState({...store.state, connected: false, connecting: false})
    },
    
}

const initialCallerState = {
    connected: false,
}
const useCallControl = globalHook(React, initialCallerState, actions)

/*
    videoServerError?<h2 style={{width:"300px", position:"absolute", top:"0px", right:"20%"}}>Error accessing video server</h2>:  

*/
const CallDialog = ()=>
{    
  const classes = useStyles();

  const [callState, callControl] = useCallControl()
  
  const onConnected = ()=>{
      callControl.connected()
  }
  const onDisconnected = ()=>{
      callControl.disconnected()
  }
  const onIncomingMessage = ()=>
  {
      // TODO: REMOVE
  }
  const studentId = (callState.connected || callState.connecting) ? callState.userId : ""
  const [localVideoRef, remoteVideoRef, videoServerError, muteFunc] = kinesisViewSupport.useKinesisViewerConnection({ studentId, sendAudio: true, sendVideo: false, onConnected, onDisconnected, classRegion: callState.classRegion})
  
  const showRemoteVideo = callState.connected
  const [localMuted, setLocalMuted] = React.useState(false)
  
  const toggleMute=()=>
  {
    setLocalMuted(current=>!current)
  }  
  
  React.useEffect(()=>{
    muteFunc(localMuted)
  },[localMuted, studentId, muteFunc])
  
  
  const { style: connectingStyle, play } = //useAnimate({ start: {}, end: {backgroundColor: "grey"} });
  useAnimateKeyframes({ 
    keyframes: ['background-color: red', 'background-color: light-red'], 
    duration: 1,
    iterationCount: "infinite", 
    direction: 'alternate',
  });
  React.useEffect(() => play(), []);  
  
  
  return (callState.connected || callState.connecting) ? (
      <div style={{width:"25%", position:"absolute", left:"10px", bottom:"75px", zIndex:"9999"}}>      
          <Grid container spacing={3} >
              {/*<Grid item xs={12}>
                <Typography variant="h6" component="h2" style={{textAlign:"left"}} className={[classes.elipsisedContent]}>
                    {callState.fullName}
                </Typography>        
              </Grid>*/}
              
              <Grid item xs={12}>              
                  <video hidden autoPlay playsInline muted style={{width:"200px"}} ref={localVideoRef}/>
                  <video hidden autoPlay playsInline style={{display: ( (showRemoteVideo&&false)?"initial" : "none"), width:"200px"}} ref={remoteVideoRef}/>  
              <Tooltip title={(localMuted?"Unmute: ":"Mute: ")+callState.fullName} placement="top-end">
                <Fab color="primary"
                    onClick={toggleMute}>
                    {localMuted? <MicOffIcon/> : <MicIcon/>}
                </Fab>
               </Tooltip>               
              </Grid>
              <Grid item xs={12}>  
              <Tooltip title={(callState.connecting?"Calling: ":"Disconnect from: ")+callState.fullName} placement="bottom-end">
                <Fab disabled={!(callState.connected || callState.connecting)} 
                    style={ callState.connecting?connectingStyle:{backgroundColor: "red"}} onClick={()=>{
                    
                    callControl.disconnect(callState.userId)
                }}>
                    <CallEndIcon fontSize="large"/>
                    </Fab>
               </Tooltip>               
              </Grid>
          </Grid>
    </div>    
    )
    : null
    
    
};

export default {
    useCallControl,
    CallDialog
}