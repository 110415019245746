import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table-6";

import gql from "graphql-tag";
import { useQuery, useMutation, useSubscription } from "@apollo/client";

import Queries from "GraphQL/InstructorAccess.js"

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import People from "@material-ui/icons/People";
import PersonAdd from "@material-ui/icons/PersonAdd";
import Close from "@material-ui/icons/Close";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import EditIcon from "@material-ui/icons/Edit";
import HistoryIcon from '@material-ui/icons/History';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import SendIcon from '@material-ui/icons/Send';
import ListIcon from '@material-ui/icons/List';
import Select from '@material-ui/core/Select';
import SettingsIcon from '@material-ui/icons/Settings';
import SaveIcon from '@material-ui/icons/SaveAlt';
import CircularProgress from '@material-ui/core/CircularProgress';
import Chip from '@material-ui/core/Chip';
import BuildIcon from '@material-ui/icons/Build';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import SchoolIcon from '@material-ui/icons/School';
import TrainerEdit from "views/Pages/TrainerEdit";
import SubscriptionHelpers from "GraphQL/SubscriptionHelpers.js"

import XLSX from 'xlsx';

import GetAppIcon from '@material-ui/icons/GetApp';
import Fab from '@material-ui/core/Fab';

import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";

import distiAuth from "disti-auth.js";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  }
};
const DefaultColumnFilter = ( { filter, onChange })  =>
{
  return (
	<input
	  value={filter ? filter.value : ''}
	  onChange={e => {
		  //console.log("Got onChange: "+ JSON.stringify(e.target.value));
		onChange(e.target.value || []) // Set undefined to remove the filter entirely
	  }}
	  placeholder={`🔍`}//Search ${count} records...`}
	/>
  )
 
}

const isSetsEqual = (a, b) => a.size === b.size && [...a].every(value => b.has(value));        
const setContainsFullyContainsSet = (a, b) => [...b].every(value => a.has(value));        
const setContainsAnyOfSet = (a, b) => [...b].some(value => a.has(value));        

const useStyles = makeStyles(styles);
  
export default () => {

  const {allowedActions, assignedMarkets} = React.useContext( distiAuth.AllowedActionsContext );

  const { loading: queryRoleLoading, data: roleQueryData } = useQuery(Queries.ROLES.ALL);
  
  const [ deleteTrainer ] = useMutation(Queries.TRAINERS.DELETE);
  
  const [ modifyTrainer, {loading: modifyTrainerLoading, error: modifyTrainerError} ] = useMutation(Queries.TRAINERS.MODIFY);
  
  const { loading: queryLoading, data: queryData } = SubscriptionHelpers.useSubscribedTrainersQuery();// useQuery(Queries.TRAINERS.ALL); 
  
  
  const NoDataComponent = ()=>{ return ( queryLoading ? 
        <CircularProgress disableShrink style={{
            position: "absolute",
            top: "200px",
            // Center it left/right:
            left: "0px",
            right: "0px",
            marginLeft: "auto",
            marginRight: "auto"
        }} />: 
        <div style={{
            position: "absolute",
            top: "200px",
            // Center it left/right:
            textAlign: "center",
            left: "0px",
            right: "0px",
            marginLeft: "auto",
            marginRight: "auto"
        }}>No Trainers Visible</div> )}

  const { loading: loadingMarketData, data: marketQueryData } = useQuery(Queries.MARKETS.ALL); 
  
  
  let marketDataById = {};
  if (marketQueryData && marketQueryData.listMarkets.items)
  {
      marketQueryData.listMarkets.items.forEach((item)=>
      {
         marketDataById[item.id] = item; 
      });
  }
  
  const [menuAnchorElement, setMenuAnchorElement] = React.useState(null);
  const [menuTargetData, setMenuTargetData] = React.useState(null);
  
  const [trainerEditData, setTrainerEditData] = React.useState(null);
  const [creatingNewTrainer, setCreatingNewTrainer] = React.useState(false);
    
  let reactTable = React.useRef(null);
  const [pageSize, setPageSize] = React.useState(null);

  let roleNamesByRoleId = {};
  let trainerList = [];
  
  if (queryData && roleQueryData)
  {
    roleNamesByRoleId = {};
    roleQueryData.listRoles.items.forEach( (item) => {
       roleNamesByRoleId[item.id] = item.name; 
    });
    
   const assignedMarketsSet = new Set( assignedMarkets )

    
    trainerList = queryData.listTrainers.items.filter(item=>{        
        const canSeeTrainer = allowedActions.noMarketRestrictions || setContainsAnyOfSet( assignedMarketsSet, new Set(item.markets ||[]) )
        return canSeeTrainer
        
    }).map(item => {
        
            // A market restricted Trainer (A) can only edit another Trainer (B) if B's markets are fully contained in A's allowed markets.  
            // Otherwise, since roles apply accross all markets (A) would be allowed to add abilities to markets they are not part of.
            const canEditTrainer = allowedActions.noMarketRestrictions || 
                                   allowedActions.assignTrainerToMarket || 
                                   setContainsFullyContainsSet( assignedMarketsSet, new Set(item.markets||[]) )
            
			return {
				id: item.id,
				email: item.id,
				fullName: item.fullName,
                roles: item.roles || [],//["marketAdmin", "facilitator"],
                markets: item.markets || [],//[{id:"123", name:"A Name"}, {id:"456", name:"Nother Name"},{id:"4568", name:"Nother Name 1"},{id:"4569", name:"Nother Name 2"},{id:"4560", name:"Nother Name 3"}],
                actions: 
                  <>
                    <Button key={item.id}
						round
                        size="sm"
						color="primary" 
                        disabled={!canEditTrainer}
						onClick={(event) => { 
                            setMenuAnchorElement(event.currentTarget);
                            setMenuTargetData(item);
                        }}
					  >
						<BuildIcon/>
                        <ArrowDropDownIcon/>
					</Button>
                  </>

			};
	   }
	   )	          
       

  }
  const doDelete = (email)=>
  {
      if (email)
      {
          const yes = window.confirm("Are you sure you want to delete the trainer: "+email+"?");
          
          if (yes)
          {
            deleteTrainer({variables: {"pk": email}});
          }
      }
  }
  const doCreate = ()=>
  {
      setCreatingNewTrainer(true);
      setTrainerEditData({})    
  }
  const handleCloseMenu = ()=>
  {
    setMenuAnchorElement(null);
    setMenuTargetData(null);
  }
  
  const dumpAllTrainerData = ()=>
     {
        const prettyList = trainerList.map(item=>
        {
            return {email: item.email,
                    fullName: item.fullName,
                    roles: item.roles.map(r=>roleNamesByRoleId[r]).join("\r\n"),
                    markets: item.markets.map(m=>(marketDataById[m] ? marketDataById[m].name : "Unknown:"+m)).join("\r\n"),
            }                           
        })
        console.log(JSON.stringify(prettyList, null, 2))
        
      const book =  XLSX.utils.book_new();
      const sheet = XLSX.utils.json_to_sheet(prettyList || []);
      XLSX.utils.book_append_sheet(book, sheet, "Trainers");
      XLSX.writeFile(book, "trainer-data.xlsx");  
     }
     
  const classes = useStyles();
    return (
	<>
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="primary" icon>
		    <CardIcon color="primary">
              <SchoolIcon />
            </CardIcon>
            <h1 className={classes.cardIconTitle}>Trainers            
            <div style={{float:"right"}}>
                {distiAuth.showDownloadButtons()?<Button round color="primary" onClick={ dumpAllTrainerData }><GetAppIcon />Export Table</Button>
                :null}
                {allowedActions.createBaseUser?
                  <Button round color="primary" onClick={ ()=>{doCreate();} }><PersonAdd />Add Trainer</Button>
                :null}
            </div>
            </h1>
          </CardHeader>
          <CardBody>
          <TrainerEdit 
            open={Boolean(trainerEditData)} 
            initialValue={trainerEditData}
            createNew={creatingNewTrainer}
            roleNamesByRoleId={roleNamesByRoleId}
            onApply={(newData)=>{
                console.log("Got apply!" + JSON.stringify({pk:newData.email, fullName: newData.fullName, roles: newData.roles, markets: newData.markets }))
                
                modifyTrainer({variables: {pk:newData.email, fullName: newData.fullName, roles: newData.roles || [], markets: newData.markets ||[] }});
             
            }}
            onValidateNewEmail={(candidateEmail)=>{
                  // Return true if it is okay
                  const found = trainerList.some( item => {return item.id.toLowerCase() == (candidateEmail || "").toLowerCase() } );
                  return !found;                    
            }}
            onClose={()=>setTrainerEditData(null)}
          />
          <Menu
                id="action-menu"
                anchorEl={menuAnchorElement}
                open={Boolean(menuAnchorElement)}
                onClose={handleCloseMenu}
              >
              <MenuItem onClick={()=>{
                      setCreatingNewTrainer(false);
                      setTrainerEditData(menuTargetData);
                      handleCloseMenu();
                  }}>Edit Trainer</MenuItem>
              <MenuItem 
                      onClick={()=>{
                      doDelete(menuTargetData.id);
                      handleCloseMenu();
                  }}>Delete Trainer</MenuItem>
        </Menu>                        
            <ReactTable ref={(r)=>{reactTable.current = r}}
              data={trainerList}
              filterable
 			  style={{
				height: "calc( 100vh - 276px)" 
			  }}
			  defaultFilterMethod={(filter, row, column) => {
				const id = filter.pivotId || filter.id
				if (row[id] !== undefined )
				{
					const haystack =(String(row[id])).toLowerCase();
					const needle = String(filter.value).toLowerCase();
					return haystack.includes(needle);
				}
				return true;
			  }}
              columns={[
                {
                  Header: "Name",
                  accessor: "fullName",
				  Filter: DefaultColumnFilter,
                },
                {
                  Header: "Email",
                  accessor: "email",
				  Filter: DefaultColumnFilter,
                },
                {
                  Header: "Role(s)",
                  accessor: "roles",
                  Cell: (row)=> row.value ? row.value.map( item => {
                      return <Chip 
                        style={{
                            margin: "1px" 
                        }}
                        label={roleNamesByRoleId[item]} key={item}/>
                      
                  }) : ""

                },
                {
                  Header: "Market(s)",
                  accessor: "markets",
                  Cell: (row)=> row.value ? row.value.map( item => {
                      return <Chip 
                        style={{
                            margin: "1px" 
                        }}
                        label={loadingMarketData ? "..." : (marketDataById[item] ? marketDataById[item].name : "Deleted Market")} key={item}/>
                      
                  }) : ""
                },
                {
                  Header: "Actions",
                  accessor: "actions",
                  sortable: false,
                  filterable: false,
                  width: 150
                }				
              ]}
			  defaultSorted={[
				{
				  id: "fullName",
				  desc: false
				}
			  ]}              
			  onFilteredChange={() => {
			    setPageSize(reactTable.current.getResolvedState().sortedData.length);
			  }}			  
              pageSize={ pageSize != null ? pageSize : trainerList.length }
			  
              showPaginationTop={false}
              showPaginationBottom={false}
              className="-striped -highlight"
              NoDataComponent={NoDataComponent}
            />

        </CardBody>
        </Card>
      </GridItem>
    </GridContainer>

	</>  );
}
