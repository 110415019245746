import React from "react";

import AWS from "aws-sdk";
import settings from "../../aws-exports.json"

import defaultContentThumbnail from "../../thumbnail_placeholder.jpg";

const imageUrlCache={}
const getPresignedUrlForContentImageById = (id, cacheExtra)=>
{
  const cached = imageUrlCache[id + cacheExtra]
  if (cached) return cached;

  const s3 = new AWS.S3({
    signatureVersion: "v4",
    region: settings.CoreRegion,
  });
  const url = s3.getSignedUrl('getObject',{
    Bucket: `${settings.CoreRegion}-${settings.Tenant}-${settings.appstreamContentS3Bucket}`,
    Key: `images/${id}.png`,
    Expires: 60*60*24,
  });
  imageUrlCache[id + cacheExtra] = url;

  return url;

}
export default function ContentImage({id, style, refresh})
{
  const [url, setUrl] = React.useState(null);
  React.useEffect(()=>{
    setUrl(getPresignedUrlForContentImageById(id, refresh))
  },[id, refresh]);

  return <div id={"image_for_"+id} style={{textAlign:"center"}}> 
      {url?
      <object style={{maxWidth:"75px", maxHeight:"50px", objectFit: "contain", ...style}} data={url} type="image/png">
        <img style={{maxWidth:"75px", maxHeight:"50px", objectFit: "contain", ...style}} src={defaultContentThumbnail}/>
      </object>
      :<img style={{maxWidth:"75px", maxHeight:"50px", objectFit: "contain", ...style}} src={defaultContentThumbnail}/>}
      </div>

}
