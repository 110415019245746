import React from 'react';
import { useQuery, useMutation, useSubscription } from "@apollo/client";
import Queries from "GraphQL/InstructorAccess.js"
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from "components/CustomButtons/Button.js";
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import LinearProgress from '@material-ui/core/LinearProgress';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import LiveTvIcon from '@material-ui/icons/LiveTv';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import UpdateIcon from '@material-ui/icons/Update';
import TextField from '@material-ui/core/TextField';
import EditIcon from '@material-ui/icons/Edit';
import PersonIcon from '@material-ui/icons/Person';
import EmailIcon from '@material-ui/icons/Email';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import InputAdornment from '@material-ui/core/InputAdornment';
import OrganizationChooser from "components/StudentTable/OrganizationChooser.js";
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
//import MarketSelector from 'components/MarketSelector/MarketSelector';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Alert from '@material-ui/lab/Alert';
import distiAuth from "disti-auth.js";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";

import util from "util.js";

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px" 
    },
  entryLine: {
    width:"100%",
    marginTop:"15px",
    marginBottom:"15px",
  }
});

export default ({open, createNew, onApply, onValidateNewEmail, onClose, initialValue, roleNamesByRoleId}) => {
    
    if (!open)
    {
        return "";
    }
  const classes = useStyles();

  const {allowedActions, assignedMarkets} = React.useContext( distiAuth.AllowedActionsContext );
  const assignedMarketsSet = new Set( assignedMarkets )

  const { data: marketQueryData } = useQuery(Queries.MARKETS.ALL); 
  
  
  const [ email, setEmail ] = React.useState(initialValue.id || "");
  const [ fullName, setFullName ] = React.useState(initialValue.fullName || "");
  const [ roles, setRoles ] = React.useState(initialValue.roles || []);
  const [ markets, setMarkets ] = React.useState(initialValue.markets || []);
  
  let marketDataById = {};
  let sortedAllMarkets = [];
  if (marketQueryData && marketQueryData.listMarkets.items)
  {
      marketQueryData.listMarkets.items.forEach((item)=>
      {
         marketDataById[item.id] = item; 
      });
      
      sortedAllMarkets = [...marketQueryData.listMarkets.items].sort((a,b)=>{
          const nameA=(a.name ||"").toUpperCase();
          const nameB=(b.name ||"").toUpperCase();
          if (nameA < nameB) return -1;
          if (nameA > nameB) return 1;
          return 0;          
        });
  }
  


  const allMarketIds = sortedAllMarkets.map((i)=>i.id)
  const availableMarketIds =  allowedActions.noMarketRestrictions ? allMarketIds 
                            : allMarketIds.filter(item=>assignedMarketsSet.has( item )) 
                            
                            
  const uneditableMarketSet = markets ? (allowedActions.noMarketRestrictions ? new Set() :
                                        new Set(markets.filter(item=>!assignedMarketsSet.has( item ))))
                                      : new Set()

  const username = distiAuth.getUsername()

                                    
  const handleCancel = ()=>{
      onClose();
  };
  const handleApply = ()=>{
      onApply({
          createNew,
          email,
          fullName,
          roles,
          markets
      });
      onClose();
  };
  
  let whyBadEmail = "";
  const emailIsGood = (candidateEmail) =>
  {
    if (!util.checkEmailFormatGood(candidateEmail))
    {
        whyBadEmail = "Invalid email format."
        return false;
    }
    if (createNew && onValidateNewEmail && !onValidateNewEmail(candidateEmail))
    {
        whyBadEmail = "Email already exists in the system."
        return false;
    }
    return true;
  }
  const handleChange = (event) => {
    console.log("Set "+event.target.name+" to "+event.target.checked);
  };  
  const addRemoveRole = (add, roleId) =>{
    let s = new Set(roles);
    if (add)
    {
        s.add(roleId);
    }
    else
    {
        s.delete(roleId);
    }
    setRoles( Array.from(s) );
  };
  const goodEmail = emailIsGood( email );
  const checkboxRoleEntry = (id, enabled)=>
  {
      return (
      <FormControlLabel
        key={id}
        control={
        <Checkbox color="primary" 
            disabled = {!Boolean(enabled)}
            checked={roles.includes(id)} 
            onChange={(event)=>{
                addRemoveRole( event.target.checked, id );
            }} name={id} />}
            label={roleNamesByRoleId[id]}
      />);
      
  };
    return (
      <Dialog open={open} onClose={handleCancel} fullWidth={ true } maxWidth={"sm"} aria-labelledby="form-dialog-title">
		<DialogTitle id="form-dialog-title"><CloseIcon style={{position: "absolute",
    right: "25px", zIndex: "1"}} fontSize="large" onClick={handleCancel}/>
</DialogTitle>
        <DialogContent>
	
        <GridContainer>
          <GridItem xs={12}>

            <Card className={classes.root}>
          <CardHeader color="primary" icon>
		    <CardIcon color="primary">
            
              <EditIcon />
            </CardIcon>
            <h1 className={classes.cardIconTitle}>{createNew ? "Add New Trainer" : "Edit Trainer"}
                
            </h1>
          </CardHeader>            
            
              <CardContent>
                {email==username ? (<Alert severity="warning">Be very careful when editing your own account because you can lock yourself out of features or markets.</Alert>): null}
                    <Typography color="textSecondary" variant="h5" component="h2" >
                    <TextField className={classes.entryLine} required 
                        disabled={!allowedActions.createBaseUser}
                        value={fullName}                         
                        id="fullName" 
                        label="Full Name" 
                        InputProps={ {
                            endAdornment: <InputAdornment position="end"><PersonIcon/></InputAdornment>, 
                            error: !fullName
                        }}
                        onChange={(e)=>{ setFullName( e.target.value ) }} 
                    />
                    <br/>                
                    <Tooltip title={whyBadEmail}>
                    <TextField className={classes.entryLine} required 
                        disabled={!createNew}
                        value={email}                         
                        id="emailAddress" 
                        label="Email Address" 
                        InputProps={ {
                            endAdornment: <InputAdornment position="end"><EmailIcon/></InputAdornment>,
                            error: !goodEmail
                        }}
                        onChange={(e)=>{ setEmail( e.target.value ) }} 
                    />
                    </Tooltip>
                        <FormControl component="fieldset" style={{marginTop:20, marginBottom:20}}>
                        <FormHelperText>Roles</FormHelperText>
                        <FormGroup style={{marginLeft:20}} row >
                        {[
                          checkboxRoleEntry("systemAdmin", allowedActions.addSystemAdminRole),
                          checkboxRoleEntry("superAdmin", allowedActions.addSuperAdminRole),
                          checkboxRoleEntry("marketAdmin", allowedActions.addMarketAdminRole),
                          checkboxRoleEntry("classAdmin", allowedActions.addClassAdminRole),                          
                          checkboxRoleEntry("facilitator", allowedActions.addFacilitatorRole),                          
                          checkboxRoleEntry("reporter", allowedActions.addReporterRole),
                        ]}                          
                        </FormGroup>
                        </FormControl>
                                        
                    <br/>                
                    {marketQueryData && marketQueryData.listMarkets.items ?
                      <Autocomplete
                        multiple
                        disabled={!allowedActions.assignTrainerToMarket}
                        disableClearable={true}
                        id="tags-standard"
                        options={availableMarketIds}
                        getOptionLabel={(id) => {return marketDataById[id] ? marketDataById[id].name : "---"}}
                        value={ markets }
                        onChange={(event, newValue)=>{
                            // Don't allow for changing markets which we don't control
                            const newValueSet = new Set(newValue)
                            uneditableMarketSet.forEach(item=>newValueSet.add(item))                        
                            setMarkets(Array.from(newValueSet))
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="standard"
                            label="Markets"
                          />
                        )}
                      />            
                    : "Loading..."}    
                    </Typography>
              </CardContent>
              
              <CardActions>
              </CardActions>
            </Card>
            
          </GridItem>
        </GridContainer>
                  <Button  
                        color="primary" 
                        style={{float:"left", marginLeft:"100px", marginTop:"20px", marginBottom:"20px"}}
						onClick={ handleApply }
                        disabled={ !(
                            goodEmail && 
                            fullName
                        )}
                    >
                     Confirm
                  </Button>
                  <Button 
                        style={{float:"right", marginRight:"100px", marginTop:"20px", marginBottom:"20px"}}
						onClick={handleCancel}>
                     Cancel
                  </Button>
        </DialogContent>
      </Dialog>	
  );
}

