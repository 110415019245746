import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table-6";

import gql from "graphql-tag";
import { useQuery, useMutation, useSubscription } from "@apollo/client";

import distiAuth from "disti-auth.js"
//import jszip from "jszip"
//import * as zip from "@zip.js/zip.js"

//import * as zip from "@zip.js/zip.js/dist/zip.min.js"

import Queries from "GraphQL/InstructorAccess.js"
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import MapIcon from '@material-ui/icons/Map';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from "@material-ui/icons/Edit";
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import BuildIcon from '@material-ui/icons/Build';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Switch from '@material-ui/core/Switch';
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import { isNullLiteral } from "@babel/types";
import ContentZipUpload from "./ContentZipUpload";
import AddIcon from '@material-ui/icons/Add';
import moment from "moment";
import ContentImage from "./ContentImage";
function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  },
  entryLine: {
    width:"100%",
    marginTop:"15px",
    marginBottom:"15px",
  }
};

const useStyles = makeStyles(styles);

const DefaultColumnFilter = ( { filter, onChange })  =>
{
  return (
	<input
	  value={filter ? filter.value : ''}
	  onChange={e => {
		  //console.log("Got onChange: "+ JSON.stringify(e.target.value));
		onChange(e.target.value || []) // Set undefined to remove the filter entirely
	  }}
	  placeholder={`🔍`}//Search ${count} records...`}
	/>
  )
 
}
const StringInput = ({className, valueData, valueKey, title, required, defaultValue, updateValue, disabled, inputValidCb, error})=>
{
  return (
  <Typography key={"typography"+valueKey} color="textSecondary" variant="h5" component="h2" >
  <TextField className={className} required={required} 
      value={valueData[valueKey] || defaultValue || ""}                         
      id={valueKey} 
      label={title} 
      disabled={Boolean(disabled)}
      InputProps={ {
          endAdornment: <InputAdornment position="end"><MapIcon/></InputAdornment>, 
          error: (inputValidCb ? !inputValidCb(valueData[valueKey]): false) || error
      }}
      onChange={(e)=>{ updateValue(valueKey, e.target.value ) }} 
  />
  </Typography>)
}
const FullContentEdit = ({open, createNew, onApply, onClose, initialValue, onValidIdCheck})=>
{
  const classes = useStyles();

  const [ value, setValue ] = React.useState({
    // Apply defaults:
    ...initialValue,
    startFileName: initialValue.startFileName || "start_all.bat",
    stopFileName: initialValue.stopFileName || "stop_all.bat",        
    })

  const updateValue = (key, value)=>
  {
    setValue(oldValue=>{return {...oldValue, [key]:value}})
  }

  const handleCancel = ()=>{
      onClose();
  };
  const handleApply = ()=>{
      onApply({
          createNew,
          value: {
            pk: value.id,
            name: value.name || "",
            description: value.description || "",
            highlights: value.highlights || "",
            availableSettings: value.availableSettings || "",
            lessonPassCriteria: value.lessonPassCriteria || "",
            startFileName: value.startFileName || "",
            stopFileName: value.stopFileName || "",
            executableNameForRunningCheck: value.executableNameForRunningCheck || "",
            tags: value.tags || [],
            zipLastUpdated: value.zipLastUpdated || "0",
          }
      });
      onClose();
  };
  return (
  <Dialog open={open} onClose={handleCancel} fullWidth={ true } maxWidth={"lg"} aria-labelledby="form-dialog-title">
  <DialogTitle id="form-dialog-title">
  <ContentImage id={value.id} style={{maxWidth:"150px", maxHeight:"150px", position:"absolute",top:"25px",zIndex:"999"}}/>

    <CloseIcon style={{position: "absolute",
  right: "25px", zIndex: "1"}} fontSize="large" onClick={handleCancel}/>
</DialogTitle>
      <DialogContent>


          <Card className={classes.root}>
        <CardHeader color="primary" icon>
      <CardIcon color="primary">
          
            <EditIcon />
          </CardIcon>
          <h1 className={classes.cardIconTitle}>{createNew ? "Add New Content" : "Edit Content"}
              
          </h1>
        </CardHeader>            
          
            <CardContent>
            <GridContainer>
        <GridItem xs={6}>
                  <StringInput disabled={!createNew} updateValue={updateValue} className={classes.entryLine} valueData={value} valueKey="id" title="Content ID" inputValidCb={createNew ? onValidIdCheck : null} />
                  <StringInput updateValue={updateValue} className={classes.entryLine} valueData={value} valueKey="name" title="Content Name" error={!value.name} />
                  <StringInput updateValue={updateValue} className={classes.entryLine} valueData={value} valueKey="description" title="Description"/>
                  <StringInput updateValue={updateValue} className={classes.entryLine} valueData={value} valueKey="highlights" title="Highlights"/>
                  <StringInput updateValue={updateValue} className={classes.entryLine} valueData={value} valueKey="tags" title="Tags"/>
        </GridItem>
        <GridItem xs={6}>
                  <StringInput updateValue={updateValue} className={classes.entryLine} valueData={value} valueKey="startFileName" title="Start File" error={!value.startFileName}/>
                  <StringInput updateValue={updateValue} className={classes.entryLine} valueData={value} valueKey="stopFileName" title="Stop File" error={!value.stopFileName}/>
                  <StringInput updateValue={updateValue} className={classes.entryLine} valueData={value} valueKey="executableNameForRunningCheck" title="Executable for running check" error={!value.executableNameForRunningCheck || !value.executableNameForRunningCheck.endsWith(".exe")}/>
        </GridItem>
      </GridContainer>
            </CardContent>
            
            <CardActions>
            </CardActions>
          </Card>
          
                <Button  
                      color="primary" 
                      style={{float:"left", marginLeft:"100px", marginTop:"20px", marginBottom:"20px"}}
          onClick={ handleApply }
                      disabled={ !value.name || !value.startFileName || !value.stopFileName || !value.executableNameForRunningCheck || !value.executableNameForRunningCheck.endsWith(".exe") }
                  >
                   Confirm
                </Button>
                <Button 
                      style={{float:"right", marginRight:"100px", marginTop:"20px", marginBottom:"20px"}}
          onClick={handleCancel}>
                   Cancel
                </Button>
      </DialogContent>
    </Dialog>	
  )
}
function filterInt(value) {
  if (/^[-+]?(\d+|Infinity)$/.test(value)) {
    return Number(value)
  } else {
    return NaN
  }
}

export default function ContentPage()
{
  const classes = useStyles();
  const [pageSize, setPageSize] = React.useState(null);
  const reactTable = React.useRef(null);

  const [menuAnchorElement, setMenuAnchorElement] = React.useState(null);
  const [menuTargetData, setMenuTargetData] = React.useState(null);

  const [fullContentEditData, setFullContentEditData] = React.useState(null);
  const [createNewContent, setCreateNewContent] = React.useState(false);

  const [fileUploadData, setFileUploadData] = React.useState(null);

  const [uploadFilename, setUploadFilename] = React.useState(null);


  const { loading: contentLoading, data: contentData } = useQuery(Queries.CONTENT.ALL);
  const [ modifyContent, {error: errorModifyingContent} ] = useMutation(Queries.CONTENT.MODIFY);
  
  const [refresh, setRefresh] = React.useState(0);
  

  const uploadZipRef = React.useRef(null);
  const uploadImageRef = React.useRef(null);


  const handleCreate = ({value, createNewContent})=>{
    modifyContent({variables: {pk: value.id || value.pk, ...value}});
  }
  const doCreate = ()=>
  {
    setCreateNewContent(true);
    setFullContentEditData({})    
  }
  const handleCloseMenu = ()=>
  {
    setMenuAnchorElement(null);
    setMenuTargetData(null);
  }  
  const doDelete = (entry)=>
  {
    window.alert("Delete of content not implemented... contact DiSTI support.")
    /*
    if (entry.id)
    {
        const yes = window.confirm("Are you sure you want to delete the Content: "+entry.name+"?");
        
        if (yes)
        {
          deleteContent({variables: {"pk": entry.id}});
        }
    }
    */
  }
  if (contentData && contentData.listContent && contentData.listContent.items)  
  {
    const tableData = contentData.listContent.items
    return (
<>
  {uploadFilename?
     <ContentZipUpload filename={uploadFilename} currentEntryData={fileUploadData} onClose={()=>
      {setUploadFilename(null);setRefresh(was=>was+1)
     }} updateEntryData={handleCreate} />
  :null}
        {Boolean(fullContentEditData)?
        <FullContentEdit 
          open={true} 
          initialValue={fullContentEditData}
          createNew={createNewContent}
          onApply={handleCreate}
          onClose={()=>setFullContentEditData(null)}
          onValidIdCheck={(candidateId)=>{
            const candidateNumber = filterInt(candidateId)

            return !isNaN(candidateNumber) && // Only numbers
                   !(tableData.some(entry=>entry.id == candidateId)) && // No existing ids
                   candidateNumber >= 1000 && /// < 1000 are reserved for Schoolhouse use
                   candidateId == (""+candidateNumber); // No leading zeros or seperators
          }}
        />:null}
        <input
          accept=".zip"
          className={classes.input}
          style={{ display: 'none' }}
          type="file"
          ref={uploadZipRef}
          onChange={(e)=>{
            setUploadFilename( e.currentTarget.files[0] );
            console.log("Got zip file: ",e.currentTarget.files[0])
            e.currentTarget.value = ''; // To allow later file selection
          }}
        />        
        <input
          accept=".png"
          className={classes.input}
          style={{ display: 'none' }}
          type="file"
          ref={uploadImageRef}
          onChange={async (e)=>{
            const file = e.currentTarget.files[0]
            e.currentTarget.value = '' // To allow later file selection
            await distiAuth.uploadContentImageBlob({contentId: fileUploadData.id, blob: file});
            setRefresh(was=>(was+1));
            //console.log("Got image file: ",e.currentTarget.files[0])
          }}
        />        
        <Menu
              id="action-menu"
              anchorEl={menuAnchorElement}
              open={Boolean(menuAnchorElement)}
              onClose={handleCloseMenu}
            >
            <MenuItem onClick={()=>{
                    setCreateNewContent(false);
                    setFullContentEditData(menuTargetData);
                    handleCloseMenu();
                }}>Edit Content Metadata</MenuItem>

            <MenuItem onClick={()=>{
                    setFileUploadData({...menuTargetData});
                    uploadZipRef.current.click()
                    handleCloseMenu();
                }}>Upload Zip File</MenuItem>

            <MenuItem onClick={()=>{
                    setFileUploadData({...menuTargetData});
                    uploadImageRef.current.click()
                    handleCloseMenu();
                }}>Upload Image File</MenuItem>

            <MenuItem onClick={()=>{
                    doDelete(menuTargetData);
                    handleCloseMenu();
                }}>Delete Content</MenuItem>
      </Menu>                        
      <GridContainer>
      <GridItem xs={12}>
      <Card>
      <CardHeader color="primary" icon>
    <CardIcon color="primary">
          <DriveEtaIcon />
        </CardIcon>
        <h1 className={classes.cardIconTitle}>Content
        <Button style={{float:"right"}} round color="primary" onClick={ ()=>{doCreate();} }><AddIcon fontSize="small" style={{marginRight:0}}/><DriveEtaIcon style={{marginLeft:0, marginRight:10}} />Add Content</Button>        

        </h1>
      </CardHeader>
      <CardBody>
        <ReactTable ref={(r)=>{reactTable.current = r}}
          data={tableData.map(item=>{
            return {
              ...item,
              actions: (
                <>
                  <Button key={item.id || item.pk}
                    round
                    size="sm"
                    color="primary" 
                    onClick={(event) => { 
                                    setMenuAnchorElement(event.currentTarget);
                                    setMenuTargetData(item);
                                }}
                    >
                    <BuildIcon/>
                    <ArrowDropDownIcon/>
                  </Button>
                  </>)
            }            
          })}
          filterable
    style={{
    height: "calc( 100vh - 276px)" 
    }}
    defaultFilterMethod={(filter, row, column) => {
    const id = filter.pivotId || filter.id
    if (row[id] !== undefined )
    {
      const haystack =(String(row[id])).toLowerCase();
      const needle = String(filter.value).toLowerCase();
      return haystack.includes(needle);
    }
    return true;
    }}
          columns={[
            {
              Header: "ID",
              accessor: "id",
      Filter: DefaultColumnFilter,
              sortable: false,
              width: 100,
            },
            {
              Header: "Content Name",
              accessor: "name",
      Filter: DefaultColumnFilter,
            },
            {
              Header: "Image",
              sortable: false,
              accessor: "imageUrl",
              Cell: params=><ContentImage id={params.original.id} refresh={refresh}/>
            },
            {
              Header: "Description",
              accessor: "description",
      Filter: DefaultColumnFilter,
            },
            {
              Header: "Highlights",
              accessor: "highlights",
              sortable: false,
              filterable: false,
            },	
            {
              Header: "Start file",
              accessor: "startFileName",
              sortable: false,
              filterable: false,
            },	
            {
              Header: "Stop file",
              accessor: "stopFileName",
              sortable: false,
              filterable: false,
            },	
            {
              Header: "Executable",
              accessor: "executableNameForRunningCheck",
              sortable: false,
              filterable: false,
            },	
            {
              Header: "Zip Last Updated",
              accessor: "zipLastUpdated",
              sortable: false,
              filterable: false,
              Cell: params=>parseInt(params.value ||"0")?moment.unix(params.value).fromNow():"---"
            },	
            {
              Header: "Available Settings",
              accessor: "availableSettings",
              sortable: false,
              filterable: false,
              Cell: params=>params.value?"Yes":"---"
            },
            {
              Header: "Tags",
              accessor: "tags",
              sortable: false,
              filterable: false,
            },
            {
              Header: "Actions",
              accessor: "actions",
              sortable: false,
              filterable: false,
            }
          ]}
    defaultSorted={[
    {
      id: "contentName",
      desc: false
    }
    ]}              
    onFilteredChange={() => {
      setPageSize(reactTable.current.getResolvedState().sortedData.length);
    }}			  
          pageSize={ pageSize != null ? pageSize : tableData.length }
    
          showPaginationTop={false}
          showPaginationBottom={false}
          className="-striped -highlight"
        />

    </CardBody>
    </Card>
    </GridItem>
    </GridContainer>
    </>)

  }
  else
  {
    return contentLoading?"Loading...":"Error";
  }

}