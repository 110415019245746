import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';

// react component for creating dynamic tables
import ReactTable from "react-table-6";

import gql from "graphql-tag";
import { useQuery, useMutation, useSubscription } from "@apollo/client";

import distiAuth from "disti-auth.js";

import settings from "../../aws-exports.json"

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import PersonAdd from "@material-ui/icons/PersonAdd";
import PersonIcon from '@material-ui/icons/Person';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Checkbox from "@material-ui/core/Checkbox";
import ErrorIcon from '@material-ui/icons/Error';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import CloseIcon from '@material-ui/icons/Close';
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import HistoryIcon from '@material-ui/icons/History';
import ListIcon from '@material-ui/icons/List';
import VideoLibraryIcon from '@material-ui/icons/VideoLibrary';
import GetAppIcon from '@material-ui/icons/GetApp';

import Queries from "GraphQL/InstructorAccess.js"

import TrainingLog from "components/TrainingLog/TrainingLog.js";

import AWS from "aws-sdk"
import { getSignedUrl } from "@aws-sdk/s3-request-presigner";
import { S3Client, GetObjectCommand } from "@aws-sdk/client-s3";

import moment from 'moment'

import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";

import ReactPlayer from 'react-player/lazy'
import CircularProgress from '@material-ui/core/CircularProgress';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  }
};

const useStyles = makeStyles(styles);

const useInterval = (callback, delay, startNow) => {
  const savedCallback = React.useRef();

  React.useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  React.useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (startNow)
    {
        tick() // Start with an immediate call
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
};

const getPresignedUrlForDownload =  async ({region, bucket, key, localFilename})=>{
    const client = new S3Client({region, credentials: AWS.config.credentials});
    // Get https urls from the S3 links
    const command = new GetObjectCommand({
        Bucket: bucket, 
        Key: key,
        ResponseContentDisposition: `attachment; filename="${localFilename}"`,
        });
    const signedUrl = await getSignedUrl(client, command, { expiresIn: 60 * 60 * 2 })

    return signedUrl
}

//let s3 = null;
const SingleVideoView = ({region, bucket, keyArg: key})=>
{
   const [url, setUrl] = React.useState(null)
   
   React.useEffect(()=>
   {
       const client = new S3Client({region, credentials: AWS.config.credentials});
       const doIt= async ()=>{
        // Get https urls from the S3 links
        const command = new GetObjectCommand({Bucket: bucket, Key: key});
        const signedUrl = await getSignedUrl(client, command, { expiresIn: 60 * 60 * 2 })
        setUrl(signedUrl)
       }
       doIt()
 
   },[region, bucket, key])

    return (
    <div style={{}}>
    <div style={{
          position: "relative",
          paddingTop: "56.25%" /* Player ratio: 100 / (1280 / 720) */
    }}>
    <ReactPlayer 
        style={{
            position: "absolute",
            top: "0",
            left: "0",
        }}
        controls={true} 
        muted={true}
        playing={true}
        height={"100%"}
        width={"100%"}
        url={url}
    />
    </div>
    </div>
)    
}
/*
const VideoBrowser = ({videos})=>
{
    if (videos && videos.releventVideos)
    {
        try {
       return videos.releventVideos.map(v=>{
         return <SingleVideoView key={v.Key} region={videos.region} video={v}/>       
       })
       
        }
        catch(e)
        {
            console.log("Problem:",e)
        }
    }
    return null
}
*/
export default function VideoHistory() {

  const { loading: classDataLoading, data: classData } = useQuery(Queries.CLASSES.ALL); 

  const { loading: marketsLoading, data: marketData } = useQuery(Queries.MARKETS.ALL);     

  const { loading: contentLoading, data: contentData } = useQuery(Queries.CONTENT.ALL);
    
  const [showVideo, setShowVideo] = React.useState(null);

  const [_count, forceUpdate] = React.useReducer(x => x + 1, 0);
  
  const [historyList, setHistoryList] = React.useState([])
  
  const [refresh, setRefresh] = React.useState(null);
  
  const [showLoading, setShowLoading] = React.useState(false);
 
  const {allowedActions, assignedMarkets} = React.useContext( distiAuth.AllowedActionsContext );
  
  const [selected, setSelected] = React.useState({})

  const [deletedAfterLoad, setDeletedAfterLoad] = React.useState(new Set())
 
  const TimedLoadingButton = ({timeOfZeroProgress, timeOfFullProgress, failed, ...params})=>
  {
      const deltaTime = timeOfFullProgress - timeOfZeroProgress                      
      const [progress, setProgress] = React.useState(0)
      const [active, setActive] = React.useState(false)
      useInterval(()=>{
        if (active)
        {
          return
        }
        const now = (Date.now() / 1000)
        const newProgress = Math.min(100,Math.round((100.0 * (now - timeOfZeroProgress)) / deltaTime))
        setProgress(newProgress)
        if (newProgress >= 100)
        {
          setActive(true)
        }
      }, 3000, true);

      return (<Button 
        {...{...params, disabled: (!active || failed)} } 
        >
        {(!active || failed) ? <span style={{color:"white"}}>{failed?"Failed\nprocessing":progress+"%\nprocessed"}</span> :
        <VideoLibraryIcon />}
                                        
      </Button>)

  }
  const FancyVideoButton = ({details, onClick})=>
  {
    return details.processing?(
      <TimedLoadingButton 
        round 
        color="primary" 
        onClick={onClick}
        failed={details.processingFailed}
        timeOfZeroProgress = {details.processingStartTimeEpoch}
        timeOfFullProgress = {details.expectedProcessingFinishEpoch}
        >
      </TimedLoadingButton>
    ):(
      <Button 
        round 
        color="primary" 
        onClick={onClick}
        >
        <VideoLibraryIcon />
      </Button>
    )
  }

  const VideoButton = ({details})=>{
    return (<FancyVideoButton
        details = {details}
        onClick={()=>{
            setShowVideo(details)
            }}
      />)
  }	
  const videoButtonCell = (params)=>
  {
    return <VideoButton details={params.original}/> 
  }
  const collectData = async ()=>
  {
    if (classDataLoading || marketsLoading || contentLoading)
    {
        return
    }
	try 
	{        
        setShowLoading(true);
        
        const assignedMarketsSet = new Set( assignedMarkets )

        // Start with an empty list
        setHistoryList([])
        
        const classNameByClassId = {};
        const dataMarketIdByClassId = {};        
        (classData.listClasses.items || []).forEach(c=>{
            dataMarketIdByClassId[c.id] = c.market
            classNameByClassId[c.id] = c.description
        })
        
        const marketNameByMarketId = {};
        (marketData.listMarkets.items || []).forEach(m=>
        {
            marketNameByMarketId[m.id] = m.name  
        })
        
        const contentNameByContentId = {};
        (contentData.listContent.items || []).forEach(c=>
        {
            contentNameByContentId[c.id] = c.name
        })
        
        let fileData = []
        const regions = [settings.CoreRegion, ...settings.SatelliteRegions]
        await Promise.all(regions.map(async (regionId)=>
        {
            try 
            {
              const reportsBucket = distiAuth.getReportsBucketByRegion(regionId)
              let s3ForRegion = new AWS.S3({region: regionId}); 

              const params = {
                Bucket: reportsBucket, 
                Prefix: 'results/'  
              };
              
              while (true)
              {
                  let response = await s3ForRegion.listObjectsV2(params).promise();
                  
                  if ( !response.Contents )
                  {
                      break;
                  }
                  console.log(`Got ${response.Contents.length} responses in region ${regionId}`)
                  const tempFileData = response.Contents.filter((item)=>(item.Key.endsWith("-content-video.mp4") || item.Key.endsWith("-content-video.mp4.processing"))).
                      map((entry)=>{
                          const key = entry.Key 
                          const splitKey = key.split('/')
                          const sizeBytes = parseInt(entry.Size)

                          const [,startTimeEpoch, endTimeEpoch] = key.split("/").pop().split("-");

                          let rval = {
                            region: regionId,
                            bucket: reportsBucket,
                            key,
                            startTimeEpoch,
                            endTimeEpoch,
                          }
                          
                          if (entry.Key.endsWith(".processing"))
                          {
                            console.log("PROCESSING: ",reportsBucket,entry.Key)
                            rval.key = entry.Key.substring(0, entry.Key.length - ".processing".length)
                            
                            const videoLength = rval.endTimeEpoch - rval.startTimeEpoch    
                            const worstCaseProcessingRatio = 0.75 /* processing time per run time */    
                            const processingStartTime = moment(entry.LastModified).unix()
  
                            rval.processing = true
                            rval.expectedProcessingFinishEpoch = parseInt(processingStartTime + videoLength * worstCaseProcessingRatio)
                            rval.processingStartTimeEpoch = processingStartTime
  
                            if (rval.expectedProcessingFinishEpoch < (Date.now()/1000))
                            {
                              // We just got the file data are past the expected time already, so this 
                              // is likely a failed processing.  This will let us flag it.
                              rval.processingFailed = true
                            }    
                          }
  
                          if (splitKey[1] === "market-data")
                          {
                              //results/market-data/96616467-86f4-4657-8c37-bb4ebae8b59a/videos/880007/asdf/range-1619111748-1619111753-content-video.mp4
                              const [,, marketId, ,contentId, description] = splitKey
                              return {...rval,
                                  sizeBytes,
                                  isFacilitatorVideo: true,
                                  marketId,
                                  contentId,
                                  description,
                              }                                    
                          }
                          else
                          {  
                              //results/student1@dynovations.com/classes/c2899b88-67ba-4b24-b43a-46c2935e1eb3/videoByContent/880007/1618527383/range-1618527398-1618527612-content-video.mp4
                              const [, username, ,classId, ,contentId] = splitKey
                              return {...rval,
                                  sizeBytes,
                                  username, 
                                  marketId: dataMarketIdByClassId[classId],
                                  classId,
                                  contentId,
                              }
                          }
                  });
                  
                  if (allowedActions.noMarketRestrictions)
                  {
                      fileData = fileData.concat( tempFileData )
                  }
                  else
                  {
                      fileData = fileData.concat( tempFileData.filter(item=>{
                          return assignedMarketsSet.has(item.marketId)
                      }))                        
                  }
                  fileData.forEach(item=>{
                    // Add some derivations for showing in the table
                    item.name = item.isFacilitatorVideo ? "Class Facilitator" : (item.username || "")
                    item.startTime = moment.unix(item.startTimeEpoch).utc().format();
                    item.durationSeconds = parseInt(item.endTimeEpoch) - parseInt(item.startTimeEpoch)
                    item.className = item.classId ? classNameByClassId[item.classId] : "---"
                    item.marketName = marketNameByMarketId[item.marketId]

                    item.description =  item.description || "---"
                    item.contentName = contentNameByContentId[item.contentId]
                    
                    item.localFilename = item.startTime +"-"+(item.username || item.description || "")+ "-" + item.contentName + ".mp4"
                    
                    item.sizeMB = parseFloat((item.sizeBytes / (1024*1024)).toFixed(2))
                    
                  });
                  if (response.IsTruncated)
                  {
                      params.ContinuationToken = response.NextContinuationToken;
                  }
                  else
                  {
                      break;
                  }
              }
            }
            catch(e)
            {
              console.log("Problem with region (skipping): ",regionId,e)

            }
          }));
          
          setHistoryList( fileData )
          setShowLoading(false);
	}	 
    catch(e)
    {
        console.log("Error collecting video history data: ",e)

    }        
  }
  React.useEffect(() =>  {
      collectData()
  }, [classDataLoading, marketsLoading, contentLoading, assignedMarkets]);
  
  const handleShowVideosCancel = ()=>
  {
    setShowVideo(null)
      
  }
  const errorColumnStyle = {
		style: {
			borderRight: "1px solid black",
			bacgkroundColor: "red"
		}
  };
  const classes = useStyles();
const largeDialogStyles = {
    dialogPaper: {
        minHeight: '80vh',
        maxHeight: '80vh',
    },
};
function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}
const deleteSelected = async ()=>
{
    const result = window.confirm(`This will permanantly delete ${Object.entries(selected).length} files.\nAre you sure you want to proceed?`)
    if (result)
    {
        Object.entries(selected).forEach(([selectedKey, details])=>
        {
            distiAuth.deleteVideoFromS3({videoKey: details.key, region: details.region}, 
            ()=>{ // Success
                // Unselect the entry
                setSelected(was=>{
                  const update = {...was}
                  delete update[selectedKey]
                  return update
                })
                // Add the entry to a list so we know not to show it without refreshing the entire list
                setDeletedAfterLoad(was=>{
                    const update = new Set(was)
                    update.add(selectedKey);
                    return update
                })
            },
            ()=>{ // Failure
                console.log("Failed to delete video: ",details)
            })
        })
    }
}
const doDownloadSelected = async ()=>
{
    // Get the real data for the selected items.
    const shortList = Object.entries(selected).map(([selectedKey, details])=>details)
    
    if (shortList.length)
    {
        for (const item of shortList)
        {
            const url = await getPresignedUrlForDownload({...item})
            
            const a = document.createElement('a');
            a.href = url;
            a.click();
            
            // We have to go slow through the downloads or we get flagged as spam.
            await sleep(3000);
        }        
    }
    
}
const filteredHistoryList = historyList.filter(item=> !deletedAfterLoad.has(item.region + "#" + item.key) )

    return (
	<>
      <Dialog open={Boolean(showVideo)} onClose={handleShowVideosCancel} fullWidth={ true }  maxWidth={"lg"} aria-labelledby="form-dialog-title">
          {showVideo?<>
			<DialogTitle id="videos-dialog">
				<span style={{float:"left"}}><br/>{showVideo.name}, {showVideo.description}, {showVideo.contentName}</span><CloseIcon style={{float:"right"}} fontSize="large" onClick={handleShowVideosCancel}/>
			</DialogTitle>
			<DialogContent>
            
            <SingleVideoView keyArg={showVideo.key} bucket={showVideo.bucket} region={showVideo.region}/>
            </DialogContent>
          </>:null}
      </Dialog>	
	    
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="primary" icon>
		    <CardIcon color="primary">
              <HistoryIcon />
            </CardIcon>
            <h1 className={classes.cardIconTitle}>Videos
            <span style={{float:"right"}}>
                <Button round color="primary" 
                    disabled={selected.size == 0}
                    onClick={ ()=>{doDownloadSelected();} }>
                    <GetAppIcon />Download Selected
                </Button>
                {allowedActions.noMarketRestrictions?
                (<Button round color="primary" 
                    disabled={selected.size == 0}
                    onClick={ ()=>{deleteSelected();} }>
                    <DeleteForeverIcon style={{color:"orangered"}} />Delete Selected
                </Button>): null}
            </span></h1>
          </CardHeader>
		
        {showLoading ?
          <CircularProgress disableShrink style={{
            position: "absolute",
            top: "50px",
            // Center it left/right:
            left: "0px",
            right: "0px",
            marginLeft: "auto",
            marginRight: "auto"
        }} /> : null} 
          <CardBody>
            <ReactTable
              data={filteredHistoryList}
			  defaultFilterMethod={(filter, row, column) => {
				const id = filter.pivotId || filter.id
				if (row[id] !== undefined )
				{
					const haystack =(String(row[id])).toLowerCase();
					const needle = String(filter.value).toLowerCase();
					return haystack.includes(needle);
				}
				return true;
			  }}
              columns={[
				{ Header: "Select",
				  width: 150,
                  sortable: false,
                  Cell: props => {
                      return (
                      <Checkbox color="primary" 
                        disabled={ props.original.processingFailed || props.original.processing }
                        checked={ selected.hasOwnProperty(props.original.region+"#"+props.original.key) } 
                        onChange={ (e) => { 				  
                            const updated = {...selected};
                            if (e.target.checked)
                            {
                                updated[props.original.region+"#"+props.original.key] = {...props.original}
                            }
                            else
                            { 
                                delete updated[props.original.region+"#"+props.original.key]
                            }
                            setSelected(updated)
                      }}/>
                  )}
    			  },
                  {Header: "User Name",
					  accessor: "name"
                  },
                  {Header: "Description",
					  accessor: "description"
                  },
                  {Header: "Market",
					  accessor: "marketName"
                  },                  
                  {Header: "Class",
					  accessor: "className"
                  },                  
                  {Header: "Content",
					  accessor: "contentName"
                  },                  
				{ Header: "Start Time",
				  headerStyle: {"textAlign": "center", borderRightStyle: "inset"},
					  accessor: "startTime"
				},
                  {Header: "Duration (seconds)",
					  accessor: "durationSeconds"
                  },                  
                  {Header: "Size (MB)",
                  accessor: "sizeMB",
                  },                                  {
                  Header: "Video",
				  headerStyle: {"textAlign": "center", borderRightStyle: "inset"},
                  Cell: videoButtonCell,

                }
              ]}
			  defaultSorted={[
				{
				  id: "dateTime",
				  desc: true
				}
			  ]}			  
              pageSize={ historyList.length }
              showPaginationTop={false}
              showPaginationBottom={false}
              className="-highlight"
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
	</>
  );
}
