import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import LinearProgress from '@material-ui/core/LinearProgress';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import LiveTvIcon from '@material-ui/icons/LiveTv';
import CardActionArea from '@material-ui/core/CardActionArea';
import MessageIcon from '@material-ui/icons/Message';
import Badge from '@material-ui/core/Badge';
import kinesisDataOnlySupport from 'components/LiveClassModal/KinesisDataOnlySupport.js'
import distiAuth from 'disti-auth.js'
import instructorChatData from 'components/FacilitatorDashboard/InstructorChatData.js'
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';

import LiveStudentDetail from "components/LiveClassModal/LiveStudentDetail.js";

import defaultContentThumbnail from "../../thumbnail_placeholder.jpg";

const useStyles = makeStyles({
  inactiveChip:{maxWidth: "200px", margin: "5px"},
  // root: {
    // minWidth: "275px",
    // //minHeight: "415px"
  // },
  // facilitator: {
    // minWidth: "175px",
    // //minHeight: "415px"
  // },
  // bullet: {
    // display: 'inline-block',
    // margin: '0 2px',
    // transform: 'scale(0.8)',
  // },
  // title: {
    // fontSize: 14,
  // },
  // pos: {
    // marginBottom: 12,
    // marginTop: 14,
  // },
  contentThumbnail: {
    maxHeight: '75px',
    maxWidth: '133px',
    objectFit: 'contain',
    float: "right",
    //width: "unset",    
    margin: "5px"
  },  
  elipsisedContent: {
    whiteSpace: "nowrap",
//    position: "relative",
    overflow: "hidden", 
    textOverflow: "ellipsis",

  },
  userThumbnail: {
    height: '75px',
    maxWidth: '133px',
    objectFit: 'contain',
    float: "left",
    //width: "unset",    
    margin: "5px"
  },  
});
const ThinLinearProgress = withStyles({
  root: {
    height: 10,
    borderRadius: 10,
  },
  bar: {
    borderRadius: 10,
  },
  colorPrimary: {
    backgroundColor: '#a4a8ab',
  },
  barColorPrimary: {
    backgroundColor: '#40bf40',
  },
})(LinearProgress);

const selectedStyle = {maxWidth: "200px", borderWidth: "3px", margin: "5px", color:"black"}
const unselectedStyle = {maxWidth: "200px", margin: "5px", color:"black"}

const ActiveChip = ({email, fullName, contentThumbnail, selected, onSelected, progress})=>{

  const {currentData, sendData, allCount, unreadCount, connected, clearUnread} = 
    instructorChatData.useChatData({passive: false, studentName: email || "NOBODY", classId: "TODO", pollInterval:5000})
  
    
    const progressStyle = {color:"black", maxWidth: "200px", borderWidth: (selected)?"3px":"", margin: "5px", background: "linear-gradient(to right, lightgreen "+progress+"%, white "+(progress+5)+"%)"}
    
    const handleOnSelected = React.useCallback( ()=>{ onSelected(email) })

    return  (<Badge 
            badgeContent={unreadCount} 
            overlap="circle"
            color="error" >
                <Chip
                avatar={<Avatar src={contentThumbnail}/>}
                key={email}
                label={fullName}
                clickable
                color="primary"
                variant={"outlined"}
                onClick={handleOnSelected}
                style={progressStyle}
              />    
          </Badge>)
}
const InactiveChip = ({email, fullName})=>{
  const classes = useStyles();
    return  <Chip
            key={email}
            label={fullName}
            color="primary"
            variant={"outlined"}
            disabled
            className={classes.inactiveChip}
          />    
}
//            style={unselectedStyle}

export default function LiveStudentDashboardCompactCard({
    progressPercent,
    email,
    fullName,
    contentThumbnail,
    studentActive, onSelected, selectedId}) {


    const progress = (progressPercent || 0)
    const progressStyle = {maxWidth: "200px", borderWidth: (email==selectedId)?"3px":"", margin: "5px", background: "linear-gradient(to right, lightgreen "+progress+"%, white "+(progress+5)+"%)"}
    const selected = (email==selectedId)
    if (studentActive)
    {
       return( 
            <ActiveChip 
                email={email}
                fullName={fullName}
                contentThumbnail={contentThumbnail}
                selected={selected}
                progress={progress}
                onSelected={onSelected}
            />)

    }
    else
    {
        return (
        <InactiveChip
            email={email}
            fullName={fullName}
        />
        )
    }
        // {/*
        // <Chip
            // avatar={item.isActive?<Avatar src={item.contentThumbnail}/>:null}
            // key={item.email}
            // label={item.fullName}
            // clickable
            // color="primary"
            // variant={"outlined"}
            // onClick={()=>onSelected(item.email)}
            // style={item.isActive?progressStyle:(selected?selectedStyle:unselectedStyle)}
          // />
        // */}
    
}
