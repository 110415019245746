import React from "react";
import distiAuth from "disti-auth.js";

let cleanupData = { 
    cleanupVideo: null,
}

const getKinesisAccessData = async({region, natTraversalDisabled, forceTURN, isMaster, studentId})=>
{ 
    return {
        
        requestSigner: { 
            getSignedURL: async ( endpoint, queryParams ) =>
                {
                    const rval = await distiAuth.getSignedKinesisURL({region, endpoint, queryParams})
                    return rval
                }
        },
        
        data: await distiAuth.getKinesisAccessData({region, natTraversalDisabled, forceTURN, isMaster, studentId})    
        
            
    }
    
}
const sharedKinesisViewerConnectionsById = {}
/*
    // Result of creation:
    "some-student@somewhere.com":
    {
        sendMessage:
        messageReceivedRef
        localVideoRef
        remoteVideoRef
        destroy:
        
    }
    
    */
const useKinesisViewerConnection = ({onIncomingMessage = null, sendMessageRef = null, studentId, sendAudio, sendVideo, onConnected, onDisconnected, classRegion})=>
{
    studentId = studentId || ""
    
    const localVideoRef = React.useRef(null);
    const remoteVideoRef = React.useRef(null);
    
    const region = classRegion;
    
    const [videoServerError, setVideoServerError] = React.useState(false)
    
    const clientId = React.useMemo(()=>{
        return ""+Date.now()
        },[])
        

    //let channelARN = ""; //'arn:aws:kinesisvideo:us-east-1:773886541566:channel/test-channel/123';
    const onRemoteDataMessage = (message)=>
    {
        if (onIncomingMessage) { onIncomingMessage(JSON.parse(message.data)); }
    }

    const muteFuncRef = React.useRef(null)
    const muteFunc = ( newMuteValue )=>
    {
        if (muteFuncRef.current)
        {
            muteFuncRef.current(newMuteValue)
        }        
    }

    const natTraversalDisabled = false
    const forceTURN = false
    const openDataChannel = true
    const useTrickleICE = true
    const widescreen = false

    const resolution = widescreen ? { width: { ideal: 1280 }, height: { ideal: 720 } } : { width: { ideal: 640 }, height: { ideal: 480 } };
    const constraints = {
        video: sendVideo ? resolution : false,
        audio: sendAudio,
    };

    const userType = distiAuth.getUserType()
    const username = distiAuth.getUsername() 
    
    const onStatsReport = (stats)=>
    {
        console.log("Stats: "+JSON.stringify(stats, null, 2))
    }
    const setupViewerVideo = async ()=>
    {
        // From: https://github.com/awslabs/amazon-kinesis-video-streams-webrtc-sdk-js
    
        const isMaster = false
        
        const iceServers = [];
        
        if (cleanupData.cleanupVideo)
        {
            cleanupData.cleanupVideo()
            cleanupData.cleanupVideo = null;
            //if (onDisconnected) onDisconnected()
        }
        
        //const localView = localVideoRef.current
        //const remoteView = remoteVideoRef.current        
        
        const { requestSigner, data: accessData } = await getKinesisAccessData({region, natTraversalDisabled, forceTURN, isMaster, studentId})
        
        console.log("KinesisAccessData: "+JSON.stringify(accessData))
        
        let dataChannel = null
        let remoteStream = null
        let peerConnectionStatsInterval = null
        
        try
        {
            // Create Signaling Client
            const signalingClient = new window.KVSWebRTC.SignalingClient({
                channelARN: accessData.channelARN,
                channelEndpoint: accessData.endpointsByProtocol.WSS,
                role: window.KVSWebRTC.Role.VIEWER,
                region: region,
                requestSigner,
                systemClockOffset: accessData.systemClockOffset,
                clientId: clientId,
            });

            console.log('ICE servers: ', accessData.iceServers);        
            let localStream = null
            
            const configuration = {
                iceServers: accessData.iceServers,
                iceTransportPolicy: forceTURN ? 'relay' : 'all',
            };


            // Create a new peer connection using the offer from the given client
            const peerConnection = new RTCPeerConnection(configuration);

            signalingClient.on('iceCandidate', candidate => {
                // Add the ICE candidate received from the MASTER to the peer connection
                console.log('[VIEWER] Received ICE candidate');
                peerConnection.addIceCandidate(candidate);
            });
            
            signalingClient.on('close', () => {
                console.log('[VIEWER] Disconnected from signaling channel');
            });

            signalingClient.on('error', error => {
                console.error('[VIEWER] Signaling client error: ', error);
            });            
            
            
            // Send any ICE candidates to the other peer
            peerConnection.addEventListener('icecandidate', ({ candidate }) => {
                if (candidate) {
                    console.log('[VIEWER] Generated ICE candidate');

                    // When trickle ICE is enabled, send the ICE candidates as they are generated.
                    if (useTrickleICE) {
                        console.log('[VIEWER] Sending ICE candidate');
                        signalingClient.sendIceCandidate(candidate);
                    }
                } else {
                    console.log('[VIEWER] All ICE candidates have been generated');

                    // When trickle ICE is disabled, send the offer now that all the ICE candidates have ben generated.
                    if (!useTrickleICE) {
                        console.log('[VIEWER] Sending SDP offer');
                        signalingClient.sendSdpOffer(peerConnection.localDescription);
                    }
                }
            });
            
            // As remote tracks are received, add them to the remote view
            peerConnection.addEventListener('track', event => {
                console.log('[VIEWER] Received remote track');
                const remoteView = remoteVideoRef.current
                //if (remoteView.srcObject) {
                //    return;
                //}
                remoteStream = event.streams[0];
                remoteView.srcObject = remoteStream;
            });
            

            if (openDataChannel) {
                dataChannel = peerConnection.createDataChannel('kvsDataChannel');
                    
                const cb = ()=>{                                
                    if (onDisconnected) onDisconnected()                    
                }
                dataChannel.onclose = cb;
                dataChannel.onerror = cb;
                    
                dataChannel.onopen = ()=>{
                    dataChannel.send(JSON.stringify({senderId: username, userType: userType, message: "CONNECTED"}))
                    
                    if (onConnected) onConnected();                    
                }                    
                peerConnection.ondatachannel = event => {
                    event.channel.onmessage = onRemoteDataMessage;
                };
                
                // Send to all connected viewers
                if (sendMessageRef)
                {
                    sendMessageRef.current = (message)=>
                    {
                        // Send only if channel is open
                        if (dataChannel.readyState == "open")
                        {
                            
                            const m = {
                                senderId: username,
                                userType: userType,
                                message: message,
                            }
                            
                          dataChannel.send(JSON.stringify(m))
                          return true
                        }
                        return false
                    }
                }
            }

            
            // Once the signaling channel connection is open, connect to the webcam and create an offer to send to the master
            signalingClient.on('open', async () => {
                try{
                    console.log('[VIEWER] Connected to signaling service');
                    
                    // Get a stream from the webcam and display it in the local view. 
                    // If no video/audio needed, no need to request for the sources. 
                    // Otherwise, the browser will throw an error saying that either video or audio has to be enabled.
                    if (sendVideo || sendAudio) {
                        try {
                            localStream = await navigator.mediaDevices.getUserMedia(constraints);
                            muteFuncRef.current = (newMuteValue)=>
                            {
                                localStream.getAudioTracks()[0].enabled = !newMuteValue
                            }
                            localStream.getTracks().forEach(track => peerConnection.addTrack(track, localStream));
                            localVideoRef.current.srcObject = localStream;
                        } catch (e) {
                            console.error('[VIEWER] Could not find webcam');
                        }
                    }            

                    // Create an SDP offer to send to the master
                    console.log('[VIEWER] Creating SDP offer');
                    await peerConnection.setLocalDescription(
                        await peerConnection.createOffer({
                            offerToReceiveAudio: true,
                            offerToReceiveVideo: true,
                        }),
                    );

                    // When trickle ICE is enabled, send the offer now and then send ICE candidates as they are generated. Otherwise wait on the ICE candidates.
                    if (useTrickleICE) {
                        console.log('[VIEWER] Sending SDP offer');
                        signalingClient.sendSdpOffer(peerConnection.localDescription);
                    }
                    console.log('[VIEWER] Generating ICE candidates');
                }
                catch(e)
                {
                    console.log("Problem in signalingClient.on('open'...): "+e);                    
                }
            });

            signalingClient.on('sdpAnswer', async answer => {
                
                if (answer.sdp == "NO")
                {
                    // TODO: I'm sure there is a proper way to reject a 'call', but this is what I did.  The master sends a specific made up SDP answer that we look for.
                    if (onDisconnected) onDisconnected()
                        
                    peerConnection.close()
                    return
                }
                
                // Add the SDP answer to the peer connection
                console.log('[VIEWER] Received SDP answer');
                await peerConnection.setRemoteDescription(answer);
            });

            console.log('[VIEWER] Starting viewer connection');
            signalingClient.open();            
            
            cleanupData.cleanupVideo = ()=>
            {
                console.log('[VIEWER] Stopping viewer connection');
                if (signalingClient) {
                    signalingClient.close();
                }

                if (peerConnection)
                {
                    peerConnection.close()
                }

                muteFuncRef.current = null
                if (localStream) 
                {
                    localStream.getTracks().forEach(track => track.stop())
                }
                if (remoteStream)
                {
                    remoteStream.getTracks().forEach(track => track.stop())
                }

                if (peerConnectionStatsInterval) {
                    clearInterval(peerConnectionStatsInterval);
                }

                if (localVideoRef.current) {
                    localVideoRef.current.srcObject = null;
                }

                if (remoteVideoRef.current) {
                    remoteVideoRef.current.srcObject = null;
                }
            }
        }
        catch(e)
        {
            console.log("Error setting up viewer video: "+e)
            setVideoServerError(true);       
            if (cleanupData.cleanupVideo)
            {
                cleanupData.cleanupVideo()
                cleanupData.cleanupVideo = null
            }
        }                
    }
    React.useEffect(()=>    
    {        
        if (region && studentId && !(sendAudio || sendVideo) ||  localVideoRef.current && remoteVideoRef.current && !videoServerError)
        {
            // TODO Add to a list of callback users, and remove from the list when the effect goes away
            
            if (!studentId)
            {
                if (cleanupData.cleanupVideo)
                {
                    cleanupData.cleanupVideo()
                }
                cleanupData.cleanupVideo = null
                return
            }
            
            setupViewerVideo()
            
            return ()=>{
                if (cleanupData.cleanupVideo)
                {
                    cleanupData.cleanupVideo()
                }
            }
        }
    },[region, sendAudio, sendVideo, localVideoRef, remoteVideoRef, studentId])    

    return [localVideoRef, remoteVideoRef, videoServerError, muteFunc]
}
export default {
    useKinesisViewerConnection,
}
