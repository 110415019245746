import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CustomButton from "components/CustomButtons/Button.js";
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import LinearProgress from '@material-ui/core/LinearProgress';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import LiveTvIcon from '@material-ui/icons/LiveTv';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CardBody from "components/Card/CardBody.js";
import Checkbox from "@material-ui/core/Checkbox";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import UpdateIcon from '@material-ui/icons/Update';
import IconButton from '@material-ui/core/IconButton';
import InstructorChat from 'components/LiveClassModal/InstructorChat.js'
import Grid from '@material-ui/core/Grid';
import CallIcon from '@material-ui/icons/Call';
import CallEndIcon from '@material-ui/icons/CallEnd';
import HistoryIcon from '@material-ui/icons/History';
import StudentHistory from "components/StudentHistory/StudentHistory.js";
import Button from "components/CustomButtons/Button.js";
import Fab from '@material-ui/core/Fab';
import distiAuth from 'disti-auth.js';
import CancelIcon from '@material-ui/icons/Cancel';

import AppStreamInstructorView from "components/LiveClassModal/AppStreamInstructorView.js";
import defaultContentThumbnail from "../../thumbnail_placeholder.jpg";

import GlobalCallControl from "components/FacilitatorDashboard/GlobalCallControl.js";

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  liveAppStream: {
    //float: 'right',
    //width: '80%',
    //paddingTop: '45%',
    position: 'relative',
    borderStyle: 'inset',
    margin: "5px"
  },  
  leftSide: {
    //float: 'left',
    position: 'relative',
    margin: "5px",
    //width: "calc(20% - 20px)"
  },  
  biggerContentImage: {
    height: '20%',
    objectFit: 'contain',
    float: "right",
    width: "unset",    
    margin: "5px"
  },  
  userThumbnail: {
    height: '75px',
    objectFit: 'contain',
    width: "unset",    
    marginBottom: "5px"
  },  
  contentThumbnail: {
    height: '100%',    
    objectFit: 'contain',
    width: "100%",    
  },  
  
});
const ThinLinearProgress = withStyles({
  root: {
    height: 10,
    borderRadius: 10,
  },
  bar: {
    borderRadius: 10,
  },
  colorPrimary: {
    backgroundColor: '#a4a8ab',
  },
  barColorPrimary: {
    backgroundColor: '#40bf40',
  },
})(LinearProgress);

const allowFacilitatorToStream = true; //window.location.search.includes("phase3=99")

const useTimeout = (callback, delay) => {
  const savedCallback = React.useRef();

  React.useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  React.useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setTimeout(tick, delay);
      return () => clearTimeout(id);
    }
  }, [delay]);
};
const useInterval = (callback, delay, startNow) => {
  const savedCallback = React.useRef();

  React.useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  React.useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (startNow)
    {
        tick() // Start with an immediate call
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
};
  const useTimedImageName = (imageName, timedOutImageName, delay) => {
      const lastImageTime = React.useRef(-1)
      const [timedOut, setTimedOut] = React.useState(true)

      React.useEffect(()=>
      {
          setTimedOut(false)
          lastImageTime.current = Date.now()          
      },[imageName])
            
      useInterval(()=>{
          if (lastImageTime.current > 0 &&
              (Date.now() - lastImageTime.current) >= delay)
          {
            setTimedOut(true)
          }        
      },delay);
      
      return timedOut || (!imageName) ? timedOutImageName : imageName      
  };
  
export default function LiveStudentDashboardDetail({item, classRegion, showChat}) {
  const classes = useStyles();
  
  const [ showHistoryOf, setShowHistoryOf ] = React.useState("");

  const {allowedActions} = React.useContext( distiAuth.AllowedActionsContext );
  
  const [callState, callControl] = GlobalCallControl.useCallControl();
  
  const [showStreamingViewForUser, setShowStreamingViewForUser] = React.useState("");
  
  let textEntry = (tooltip, text)=>
  {
      return (<Tooltip title={tooltip}>
        <Typography className={classes.pos} color="textSecondary">
            {text}
        </Typography>
        </Tooltip>);
  }

  const contentThumbnailImage = useTimedImageName(item ? item.contentThumbnail : null, defaultContentThumbnail, 60000)
/*  if (item && !item.isActive)
  {
      return(
    <Typography variant="h5" component="h2" style={{}}>
        {item.fullName} is Inactive
    </Typography>        
      )
      
  }
  */
    return (<>
    
    {showHistoryOf?
    <Dialog open={true} onClose={()=>{showHistoryOf("")}} fullWidth={ true } maxWidth={false} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">
            <CloseIcon style={{float:"right"}} fontSize="large" onClick={()=>setShowHistoryOf("")}/>
        </DialogTitle>
        <DialogContent>
            <StudentHistory
                username={showHistoryOf}
            />
        </DialogContent>
    </Dialog>
    :null}
    

    <GridContainer>
      <GridItem xs={12}>

          {item ?
    <Card className={classes.root}>
      <CardContent>
        <Grid container spacing={2}>
        <Grid item xs={10}>
            {(item.facilitator)?
            <Typography variant="h5" component="h2" style={{/*float:"left"*/}}>
                All Facilitators
            </Typography>        
            
            :<Tooltip title={item.email}>
            <Typography variant="h5" component="h2" style={{/*float:"left"*/}}>
                {item.fullName} {!item.isActive?"is not active":""}                     
					{(allowedActions.viewStudentResults && !item.facilitator) ? 
                        <Tooltip title="View Student History">
                        <Fab 
                        style={{marginLeft:"30px"}}
                        disabled={!allowedActions.viewStudentResults}
						color="primary" 
						onClick={() => { setShowHistoryOf(item.email); }}
					  >
                        <HistoryIcon fontSize="default" />
					</Fab>
                    </Tooltip>
                    :null}    
            </Typography>        
            </Tooltip>
            }
        </Grid>
        {(item.isActive && !item.facilitator)?(<>
            {showChat?
                <Grid item xs={2} style={{textAlign:"right"}}>
                <Tooltip title={"Initiate Audio Chat"}>
                        
                    <Fab disabled={(callState.connected || callState.connecting)} style={{backgroundColor: "green"}} onClick={()=>{
                        
                        callControl.call(item.email, item.fullName, classRegion)
                        
                    }}><CallIcon fontSize="large"/></Fab>
                    
                </Tooltip>
                
                </Grid>
            :null}
            <Grid item xs={12}>
            <Tooltip title={"Lesson Progress: "+Math.round(item.progressPercent)+"%"}>
                <ThinLinearProgress
                    variant="determinate"
                    value={ item.progressPercent}
                    style={{ width: "100%", display: "inline-block" }}
                />
            </Tooltip>
            </Grid>
              <Grid item xs={2}>
                <div className={classes.leftSide}>
                {textEntry("Training Mode", item.trainingMode)}
                {textEntry("Content", item.currentContent)}
                {textEntry("Current Lesson Name", item.currentLesson)}
                {textEntry("Elapsed Time in Current Lesson", item.elapsedTime)}
                <hr/>
               <CardMedia
                component="img"
                className={classes.userThumbnail}
                image={item.userThumbnail}
              />
                {textEntry("Connected Time", item.connectedTime)}
                {textEntry("Connected From Country", item.countryName)}
                {textEntry("Market", item.market)}            
                {textEntry("Dealer", item.dealer)}            
                </div>
              </Grid>  
              <Grid item xs={7}>
                    {showStreamingViewForUser==item.email?
                     <div style={{textAlign: "center", height:"100%"}}>
                        <div className={classes.liveAppStream} style={{
                                position:"relative", 
                                maxHeight: "500px", 
                                width: "100%",
                                height: "100%",
                                //maxWidth: "fit-content",
                                display:"flex", 
                                flexDirection: "column",
            //                    background: "black",
                                }}>
                                <Tooltip title="Close live view">
                                <IconButton style={{position: "absolute", top:"2px", right:"2px", zIndex:"9"}} onClick={()=>{
                                    setShowStreamingViewForUser("")
                                }}><CancelIcon/></IconButton>
                                </Tooltip>
                        <AppStreamInstructorView streamingURL={item.launchURL} />               
                        </div>
                     </div>
                    :<>                
                     <div style={{textAlign: "center"}}>
                      <div className={classes.liveAppStream} style={{
                            position:"relative", 
                            maxHeight: "500px", 
                            maxWidth: "fit-content",
                            display:"flex", 
                            flexDirection: "column",
                            }}>
                        
                        <CustomButton style={{position:"absolute", margin:"5px"}} onClick={()=>{
                            if (true)//window.location.search.includes("phase3=66"))
                            {
                                setShowStreamingViewForUser(item.email)
                            }
                            else
                            {
                                window.alert("Live View is temporarily disabled.") // TODO: Drop this
                            }
                            }}>Show Live View</CustomButton>                        
                       <CardMedia
                        component="img"
                        className={classes.contentThumbnail}
                        image={contentThumbnailImage}          
                        /> 
                    </div>
                    </div>
                    </>                
                    }
              </Grid>  
              {showChat?
              <Grid item xs={3}>
                <div style={{position: "relative", margin:"5px"}}>
                    <InstructorChat  studentId={item.email} />
                </div>
              </Grid>  
              :null}
        </>):<>
        {item.facilitator?<>
              <Grid item xs={6}>
                <div style={{position: "relative", margin:"5px"}}>
                    <InstructorChat  studentId={item.email} />
                </div>
              </Grid>  
              {allowFacilitatorToStream?(
              <Grid item xs={6}>
                     <div style={{textAlign: "center"}}>
                      <div className={classes.liveAppStream} style={{
                            position:"relative", 
                            maxHeight: "500px", 
                            maxWidth: "fit-content",
                            display:"flex", 
                            flexDirection: "column",
                            }}>
                        
                        <CustomButton disabled={!Boolean(item.launchURL)} style={{position:"absolute", margin:"5px"}} onClick={
                           ()=>{
                              const win = window.open(item.launchURL, '_blank');
                              win.focus();                    
                        }}>Access Facilitator Streaming in Tab</CustomButton>                        
                       <CardMedia
                        component="img"
                        className={classes.contentThumbnail}
                        image={contentThumbnailImage}          
                        /> 
                    </div>
                    </div>
              
              </Grid>)
              :null}  
        </>:null}
        </>
        }
        </Grid>
      </CardContent>
    </Card>
          :"---"}
      </GridItem>
    </GridContainer>
    </>

  )
}

