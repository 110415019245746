import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';

// react component for creating dynamic tables
import ReactTable from "react-table-6";

import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import ClockIcon from "@material-ui/icons/WatchLater";
import UpdateIcon from '@material-ui/icons/Update';

// @material-ui/core components
import { makeStyles, withStyles } from "@material-ui/core/styles";
// @material-ui/icons
import PersonAdd from "@material-ui/icons/PersonAdd";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Grid from '@material-ui/core/Grid';
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Checkbox from "@material-ui/core/Checkbox";
import CustomLinearProgress from "components/CustomLinearProgress/CustomLinearProgress.js";
import LinearProgress from '@material-ui/core/LinearProgress';
import LiveStudentDashboardCompactCard from "./LiveStudentDashboardCompactCard.js";
import LiveStudentDashboardCard from "./LiveStudentDashboardCard.js";
import Badge from '@material-ui/core/Badge';

import CloseIcon from '@material-ui/icons/Close';

import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
//import kinesisDataOnlySupport from 'components/LiveClassModal/KinesisDataOnlySupport.js'
import instructorChatData from 'components/FacilitatorDashboard/InstructorChatData.js'

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  },
  gridCards: {
      padding: "15px 15px !important;"
  },
    mainDialog: {
        minHeight: '90vh'
    }
    
};

const useStyles = makeStyles(styles);

export default function LiveClassList({classId, showActiveOnly, onSelected, studentList, className, compactMode, selectedId, showWebcam, doScroll}) {
	
  const [ studentSearch, setStudentSearch ] = React.useState("")
  
  const unreadCountById = instructorChatData.getCurrentUnreadCountById()
  
const classes = useStyles();


// const studentActive = (item)=>
// {
    // const active = item.countryName  && item.countryName != "--" && 
                   // item.trainingMode && !["SessionEnded"].includes(item.trainingMode); 
    // return active;
// } 
    return (
       <Grid container style={(compactMode||!doScroll)?{}:{flexWrap:"nowrap", overflowX:"scroll"}}>
          {
            studentList.filter((value)=>{
                if (studentSearch && !( (value.fullName && value.fullName.toLowerCase().includes( studentSearch.toLowerCase() )) || 
                                        (value.email   && value.email.toLowerCase().includes(   studentSearch.toLowerCase() )) ))
                {
                    return false;
                }
                if (showActiveOnly)
                {
                    return value.isActive;                        
                }
                else
                {
                    return true;
                }
            }).sort((a,b) => { 
                let rval = 0;
                if (a.isActive)
                {
                    rval -= 2;
                }
                if (b.isActive)
                {
                    rval += 2;
                }
                if (!compactMode)
                {
                    const unreadA = unreadCountById[a.email] || 0
                    const unreadB = unreadCountById[b.email] || 0
                    if ( unreadA > unreadB )
                    {
                        rval -= 2;
                    }
                    else if (unreadA < unreadB)
                    {
                        rval += 2;                    
                    }
                }
                rval += a.fullName.localeCompare( b.fullName );
                return rval;
                
                }).map((value, index)=> {
                    
                    
                if (compactMode)
                {
                    return (<LiveStudentDashboardCompactCard 
                        key={value.email}
                        progressPercent={value.progressPercent}
                        email={value.email}
                        fullName={value.fullName}
                        contentThumbnail={value.contentThumbnail}                    
                        key={value.email} 
                        classDescription={className} 
                        studentActive={value.isActive}
                        onSelected={onSelected}
                        selectedId={selectedId}
                        >
                    </LiveStudentDashboardCompactCard>
                    )
                }else
                {
                    return <Grid item style={{maxWidth:"350px", minWidth:"350px"}}
                                className={classes.gridCards} 
                                key={value.email} xs={3}>
                            <LiveStudentDashboardCard 
                                key={value.email}
                                item={value} 
                                classDescription={className} 
                                studentActive={value.isActive}
                                onSelected={onSelected}
                                selectedId={selectedId}
                                showWebcam={showWebcam}
                                >
                            </LiveStudentDashboardCard>
                        </Grid>                  
                }
          })}
      </Grid>
  );
}
